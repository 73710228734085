export class ApiEndpointsService {
  endpoints: ApiEndpointsConfig;
  mappings: { [key: string]: string } = {};

  constructor(config: ApiEndpointsConfig) {
    this.endpoints = config;
  }

  add(entityName: string, endpoint: string) {
    this.mappings[entityName] = endpoint;
  }

  get(entityName: string) {
    return this.mappings[entityName];
  }
}

export interface ApiEndpointsConfig {
  bookings: string;
  bookingMappings: string;
  exception: string;
  requests: string;
  masterdata: string;
  dynamicConnect: string;
}

export const apiEndpointsConfigStub: ApiEndpointsConfig = {
  exception: 'https://exception/api',
  bookings: 'https://bookings/api/bookings',
  bookingMappings: 'https://bookingMappings/api/mappings',
  requests: 'https://requests/api/requests',
  masterdata: 'https://masterdata/api',
  dynamicConnect: 'https://dynamicConnect/api'
};
