import { Pipe, PipeTransform } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'hasRole'
})
export class HasRolePipe implements PipeTransform {
  private readonly authState$: Observable<{
    roles: string[];
  }>;

  constructor(store: Store<fromRoot.State>) {
    this.authState$ = store.pipe(select(fromRoot.selectAuthState));
  }

  transform(value: any, ..._args: any[]): Observable<boolean> {
    return this.authState$.pipe(map(r => r.roles && r.roles.includes(value)));
  }
}
