import { Directive, AfterViewInit, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements AfterViewInit {
  @Input() public appAutoFocus = true;

  public constructor(private readonly elementRef: ElementRef) {}

  public ngAfterViewInit() {
    if (this.appAutoFocus) {
      setTimeout(() => this.elementRef.nativeElement.focus(), 0);
    }
  }
}
