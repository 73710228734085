import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import * as FileSaver from 'file-saver';
import { filter, Observable, Subscription } from 'rxjs';
import { BookingActions } from '../../actions/booking.actions';
import { BookingViewerParams } from '../../data/bookingviewer-params';
import { CoreConsumerMessage } from '../../data/core-consumer-message/core-consumer-message';
import {
  isCoreConsumerMessage,
  RequestEntity
} from '../../data/request-entity';
import * as fromShared from '../../reducers';

@Component({
  selector: 'app-bookingviewer',
  templateUrl: './bookingviewer.component.html',
  styleUrls: [
    './bookingviewer.subcomponent.scss',
    './bookingviewer.component.scss'
  ]
})
export class BookingViewerComponent implements OnInit, OnDestroy {
  @ViewChild('errorMessage') errorMessage: TemplateRef<any>;
  requestEntity$: Observable<
    RequestEntity<CoreConsumerMessage> | RequestEntity<any>
  >;
  error$: Observable<any>;
  travelPeriodStart: Date;
  travelPeriodEnd: Date;
  private readonly subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public bookingViewerParams: BookingViewerParams,
    private readonly store: Store<fromShared.State>,
    private readonly dialogRef: MatDialogRef<BookingViewerComponent>
  ) {}

  ngOnInit() {
    this.store.dispatch(
      BookingActions.getBooking({
        tourOperator: this.bookingViewerParams.tourOperator,
        externalReservationNumber: this.bookingViewerParams.reservationNumber,
        version: this.bookingViewerParams.version
      })
    );
    this.requestEntity$ = this.store.pipe(select(fromShared.selectBooking));
    this.error$ = this.store.pipe(select(fromShared.selectBookingLoadError));
    this.subscriptions.add(
      this.requestEntity$
        .pipe(filter(isCoreConsumerMessage))
        .subscribe(r => this.setTravelPeriod(r))
    );
  }

  ngOnDestroy() {
    this.store.dispatch(BookingActions.clearBookingStore());
    this.subscriptions.unsubscribe();
  }

  isCoreConsumerMessage = isCoreConsumerMessage;

  download(r: RequestEntity<any>) {
    const blob = new Blob([JSON.stringify(r.request, null, 4)], {
      type: 'application/json;charset=utf-8'
    });

    FileSaver.saveAs(
      blob,
      `${this.bookingViewerParams.tourOperator}-${this.bookingViewerParams.reservationNumber}-${this.bookingViewerParams.version}.json`,
      { autoBom: false }
    );
  }

  private setTravelPeriod(entity: RequestEntity<CoreConsumerMessage>): void {
    const arrivalTimes = entity.request.Booking.Flights.flatMap(
      f => f.FlightLegs
    )
      .filter(fl => fl.Direction == 'Out')
      .map(fl => Date.parse(fl.ArrivalDateTime));
    this.travelPeriodStart =
      arrivalTimes.length != 0 ? new Date(Math.max(...arrivalTimes)) : null;

    const departureTimes = entity.request.Booking.Flights.flatMap(
      f => f.FlightLegs
    )
      .filter(fl => fl.Direction == 'In')
      .map(fl => Date.parse(fl.DepartureDateTime));
    this.travelPeriodEnd =
      departureTimes.length != 0 ? new Date(Math.min(...departureTimes)) : null;
  }

  close() {
    this.dialogRef.close();
  }
}
