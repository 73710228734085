import { Injectable } from '@angular/core';
import { ToastActions } from '@app/modules/core/actions/toast.action';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ExternalMappingActions } from '../actions/externalmapping.actions';
import { ExternalMappingService } from '../services/externalmapping.service';

@Injectable()
export class ExternalMappingEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly externalMappingService: ExternalMappingService
  ) {}

  onGetExternalMappings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalMappingActions.getExternalMappings),
      switchMap(req =>
        this.externalMappingService.get(req.filterParams).pipe(
          map(listResult =>
            ExternalMappingActions.getExternalMappingsSuccess({
              listResult
            })
          ),
          catchError(error => {
            return of(ToastActions.errorToast({ message: error.error }));
          })
        )
      )
    )
  );

  onDeleteExternalMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalMappingActions.deleteExternalMapping),
      switchMap(req =>
        this.externalMappingService.delete(req.externalMapping).pipe(
          switchMap(_ => [
            ExternalMappingActions.deleteExternalMappingSuccess(),
            ToastActions.successToast({
              message: 'Successfully deleted'
            }),
            ExternalMappingActions.getExternalMappings({})
          ]),
          catchError(error => {
            return of(ToastActions.errorToast({ message: error.error }));
          })
        )
      )
    )
  );

  onUpsertExternalMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalMappingActions.upsertExternalMapping),
      switchMap(req =>
        this.externalMappingService.upsert(req.externalMapping).pipe(
          map(_ => ExternalMappingActions.upsertExternalMappingSuccess()),
          catchError(error => {
            return of(ToastActions.errorToast({ message: error.error }));
          })
        )
      )
    )
  );

  onSuccessfulUpsertExternalMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExternalMappingActions.upsertExternalMappingSuccess),
      switchMap(_ => [
        ToastActions.successToast({
          message: 'Successfully saved'
        }),
        ExternalMappingActions.getExternalMappings({}),
        ExternalMappingActions.unselectExternalMapping()
      ])
    )
  );
}
