import {
  CoreConsumerMessage,
  coreConsumerMessageStub
} from './core-consumer-message/core-consumer-message';

export interface RequestEntity<T> {
  externalReservationNumber: string;
  tourOperatorCode: string;
  externalVersion: string;
  request: T;
}

export function isCoreConsumerMessage(
  r: RequestEntity<any>
): r is RequestEntity<CoreConsumerMessage> {
  return (
    r &&
    r.tourOperatorCode !== 'HTX' &&
    r.tourOperatorCode !== 'LOV' &&
    (r.request as CoreConsumerMessage).Booking !== undefined
  );
}

export const atComRequestEntityStub: RequestEntity<CoreConsumerMessage> = {
  externalReservationNumber: '123456789',
  tourOperatorCode: 'DTD',
  externalVersion: '1',
  request: coreConsumerMessageStub
};

export const htxRequestEntityStub: RequestEntity<any> = {
  externalReservationNumber: 'HTXA-14896999',
  tourOperatorCode: 'HTX',
  externalVersion: '1',
  request: {
    BookingStatus: 'Booked',
    SupplierReference: 'HTXA-14896999'
  }
};
