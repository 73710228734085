import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiEndpointsService } from '../../core/services/api-endpoints.service';
import { FlightInfo } from '../../shared/data/flight-info';

@Injectable({
  providedIn: 'root'
})
export class FlightInfoService {
  constructor(
    private readonly http: HttpClient,
    private readonly api: ApiEndpointsService
  ) {}

  get(
    tourOperator: string,
    reservationNumber: string,
    version: string
  ): Observable<FlightInfo[]> {
    const url = new URL(
      `/api/requests/${tourOperator}/${reservationNumber}/${version}/flightinfos`,
      this.api.endpoints.requests
    ).toString();

    return this.http
      .get<FlightInfo[]>(url, {
        observe: 'response',
        responseType: 'json'
      })
      .pipe(map(resp => resp.body));
  }

  save(
    tourOperator: string,
    reservationNumber: string,
    version: string,
    flightInfos: FlightInfo[]
  ): Observable<FlightInfo[]> {
    const url = new URL(
      `/api/requests/${tourOperator}/${reservationNumber}/${version}/flightinfos`,
      this.api.endpoints.requests
    ).toString();
    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/json'
    );

    return this.http.put<FlightInfo[]>(url, flightInfos, { headers });
  }
}
