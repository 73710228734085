import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastActions } from '@app/modules/core/actions/toast.action';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MasterDataActions } from '../actions/masterdata.actions';
import { MasterdataService as MasterDataService } from '../services/masterdata.service';

@Injectable()
export class MasterDataEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly masterDataService: MasterDataService
  ) {}

  onRequestRoomTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MasterDataActions.getRoomTypes),
      switchMap(req =>
        this.masterDataService.getRoomTypes(req.tenant, req.roomInput).pipe(
          map(returnedRooms =>
            MasterDataActions.getRoomTypesSuccess({
              roomTypes: returnedRooms
            })
          ),
          catchError(error => {
            return of(ToastActions.errorToast({ message: error.message }));
          })
        )
      )
    )
  );
}
