<div class="information-tab">
  <div class="scrollableMemoContent">
    <ng-container *ngFor="let flight of flightSummaries">
      <dsa-object-header
        class="dsaObjectHeaderStyle"
        [isExpanded]="true"
        [onlyExtended]="true"
      >
        <h3>
          {{
            flight.firstFlightLeg.Direction === "In" ? "Departure" : "Arrival"
          }}
          Flight - {{ flight.firstFlightLeg.DepartureAirportCode }} ({{
            flight.firstFlightLeg.DepartureCountryCode
          }}) → {{ flight.lastFlightLeg.DestinationAirportCode }} ({{
            flight.lastFlightLeg.DestinationCountryCode
          }}) {{ flight.layovers }}
        </h3>
        <ul>
          <li>
            <span>Departure:</span>
            <span>{{
              (flight.firstFlightLeg.DepartureDateTime
                | date: "dd/MM/yyyy HH:mm") || "-"
            }}</span>
            <span>Arrival:</span>
            <span>{{
              (flight.lastFlightLeg.ArrivalDateTime
                | date: "dd/MM/yyyy HH:mm") || "-"
            }}</span>
          </li>
          <li>
            <span>Passengers:</span>
            <span class="maxedColumn">{{ flight.passengersString }}</span>
            <span> Service Status:</span>
            <span>
              {{ flight.serviceStatus || "-" }}
            </span>
          </li>
          <li>
            <span>Flightinfo:</span>
            <span class="maxedColumn"
              >{{ flight.lastFlightLeg.IataOperatingCarrierCode }} -
              {{ flight.lastFlightLeg.IataFlightNumber }} ({{
                flight.lastFlightLeg.IataOperatingCarrierName
              }}) - {{ flight.lastFlightLeg.FlightClassCode }}</span
            >
          </li>
        </ul></dsa-object-header
      >
    </ng-container>
  </div>
</div>
