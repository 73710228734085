import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class InstanaService {
  /* istanbul ignore next */
  setPage(pageName: string) {
    if (environment.instanaKey !== '') {
      ineum('page', pageName);
    }
  }

  init(key: string) {
    ineum('reportingUrl', 'https://eum.instana.rewe.de/eum/');
    ineum('key', key);
    ineum('trackSessions');
  }
}
