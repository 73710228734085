import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryParams } from '@ngrx/data';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ApiEndpointsService } from '../../core/services/api-endpoints.service';
import { Hotel } from '../data/hotel';
import { HotelIndex } from '../data/hotel-index';
import { SearchOutput } from '../data/searchOutput';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class HotelService implements DataService<Hotel> {
  constructor(
    private readonly http: HttpClient,
    private readonly api: ApiEndpointsService
  ) {
    this._lastParams = {};
  }

  private _lastParams: QueryParams;

  search(
    force: boolean,
    queryParams?: QueryParams
  ): Observable<SearchOutput<HotelIndex>> {
    queryParams ??= this._lastParams;

    const params: QueryParams = {
      limit: '15',
      offset: '0',
      ...queryParams
    };

    if (!this.paramsDidChange(params) && !force) {
      return of(null);
    }

    const result = this.http
      .get<SearchOutput<HotelIndex>>(
        this.api.endpoints.dynamicConnect + '/Hotel/Search',
        {
          observe: 'response',
          responseType: 'json',
          params
        }
      )
      .pipe(
        map(resp => resp.body),
        shareReplay({ bufferSize: 1, refCount: true })
      );

    this._lastParams = params;

    return result;
  }

  get(
    hotelCode: string,
    hotelchainCode: string,
    supplier: string
  ): Observable<Hotel> {
    return this.http.get<Hotel>(this.api.endpoints.dynamicConnect + '/Hotel', {
      params: { hotelCode, hotelchainCode, supplier }
    });
  }

  upsert(hotel: Hotel): Observable<string> {
    return this.http.post<string>(
      this.api.endpoints.dynamicConnect + '/Hotel',
      hotel
    );
  }

  delete(hotel: HotelIndex): Observable<string> {
    return this.http.delete<string>(
      this.api.endpoints.dynamicConnect + '/Hotel',
      {
        body: hotel
      }
    );
  }

  deleteContent(
    hotelCode: string,
    hotelChainCode: string,
    supplier: string
  ): Observable<string> {
    return this.http.delete<string>(
      this.api.endpoints.dynamicConnect + '/Hotel/Content',
      {
        params: { hotelCode, hotelChainCode, supplier }
      }
    );
  }

  exists(
    hotelCode: string,
    hotelchainCode: string,
    supplier: string
  ): Observable<boolean> {
    return this.http.get<boolean>(
      this.api.endpoints.dynamicConnect + '/Hotel/Exists',
      {
        params: { hotelCode, hotelchainCode, supplier }
      }
    );
  }

  private paramsDidChange(params: QueryParams): boolean {
    return JSON.stringify(this._lastParams) !== JSON.stringify(params);
  }
}

export class HotelServiceStub {
  search(
    _force: boolean,
    _queryParams: QueryParams
  ): Observable<SearchOutput<HotelIndex>> {
    return of();
  }
  get(
    _hotelCode: string,
    _hotelchainCode: string,
    _supplier: string
  ): Observable<Hotel> {
    return of({} as Hotel);
  }
  /* istanbul ignore next */
  upsert(_hotel: Hotel): Observable<string> {
    return of('');
  }
  /* istanbul ignore next */
  delete(_hotel: HotelIndex): Observable<string> {
    return of('');
  }
  /* istanbul ignore next */
  deleteContent(
    _hotelCode: string,
    _hotelChainCode: string,
    _supplier: string
  ): Observable<string> {
    return of('');
  }
  /* istanbul ignore next */
  exists(
    _hotelCode: string,
    _hotelchainCode: string,
    _supplier: string
  ): Observable<boolean> {
    return of({} as boolean);
  }
}
