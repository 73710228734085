import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiEndpointsService } from '../../core/services/api-endpoints.service';
import { Hotel } from '../data/hotel';

@Injectable({
  providedIn: 'root'
})
export class AriService {
  constructor(
    private readonly http: HttpClient,
    private readonly api: ApiEndpointsService
  ) {}

  deleteHotel(
    hotelCode: string,
    hotelChainCode: string,
    supplier: string
  ): Observable<string> {
    return this.http.delete<string>(
      this.api.endpoints.dynamicConnect + '/Ari/Hotel',
      {
        params: {
          hotelCode,
          hotelChainCode,
          supplier
        }
      }
    );
  }
}

export class AriServiceStub {
  /* istanbul ignore next */
  deleteHotel(
    _hotel: Hotel,
    _hotelChainCode: string,
    _supplier: string
  ): Observable<string> {
    return of('');
  }
}
