import { Passenger, passengerStubs } from './passenger';

export interface FlightLeg {
  DepartureAirportCode: string;
  DepartureCountryCode: string;
  DestinationAirportCode: string;
  DestinationCountryCode: string;
  IataFlightNumber: number;
  IataOperatingCarrierCode: string;
  IataOperatingCarrierName: string;
  DepartureDateTime: string;
  ArrivalDateTime: string;
  Direction: 'In' | 'Out';
  FlightClassCode: string;
  Passengers: Passenger[];
  ServiceStatus: null | 'CON' | 'REQ' | 'CNX';
}

export const arrivalflightLegStub: FlightLeg = {
  DepartureAirportCode: 'FDH',
  DepartureCountryCode: 'DE',
  DestinationAirportCode: 'PMI',
  DestinationCountryCode: 'ES',
  IataFlightNumber: 3102,
  IataOperatingCarrierCode: 'XC',
  IataOperatingCarrierName: 'Corendon Airlines',
  DepartureDateTime: '2022-01-21T19:00:00',
  ArrivalDateTime: '2022-01-21T20:05:00',
  Direction: 'Out',
  FlightClassCode: 'Economy',
  Passengers: passengerStubs,
  ServiceStatus: 'REQ'
};

export const arrivalflightLegStubs: FlightLeg[] = [
  {
    DepartureAirportCode: 'FDH',
    DepartureCountryCode: 'DE',
    DestinationAirportCode: 'AGP',
    DestinationCountryCode: 'ES',
    IataFlightNumber: 420,
    IataOperatingCarrierCode: 'XC',
    IataOperatingCarrierName: 'Corendon Airlines',
    DepartureDateTime: '2022-01-21T05:00:00',
    ArrivalDateTime: '2022-01-21T18:00:00',
    Direction: 'Out',
    FlightClassCode: 'Economy',
    Passengers: passengerStubs,
    ServiceStatus: 'CNX'
  },
  {
    DepartureAirportCode: 'AGP',
    DepartureCountryCode: 'ES',
    DestinationAirportCode: 'PMI',
    DestinationCountryCode: 'ES',
    IataFlightNumber: 3102,
    IataOperatingCarrierCode: 'XC',
    IataOperatingCarrierName: 'Corendon Airlines',
    DepartureDateTime: '2022-01-21T19:00:00',
    ArrivalDateTime: '2022-01-21T20:05:00',
    Direction: 'Out',
    FlightClassCode: 'Economy',
    Passengers: passengerStubs,
    ServiceStatus: 'CON'
  },
  {
    DepartureAirportCode: 'PMI',
    DepartureCountryCode: 'ES',
    DestinationAirportCode: 'LPA',
    DestinationCountryCode: 'ES',
    IataFlightNumber: 1202,
    IataOperatingCarrierCode: 'XC',
    IataOperatingCarrierName: 'Corendon Airlines',
    DepartureDateTime: '2022-01-21T21:00:00',
    ArrivalDateTime: '2022-01-21T23:00:00',
    Direction: 'Out',
    FlightClassCode: 'Economy',
    Passengers: passengerStubs,
    ServiceStatus: 'CON'
  }
];

export const departureflightLegStubs: FlightLeg[] = [
  {
    DepartureAirportCode: 'VLL',
    DepartureCountryCode: 'ES',
    DestinationAirportCode: 'PMI',
    DestinationCountryCode: 'ES',
    IataFlightNumber: 1302,
    IataOperatingCarrierCode: 'DC',
    IataOperatingCarrierName: 'DC Airlines',
    DepartureDateTime: '2022-01-28T02:00:00',
    ArrivalDateTime: '2022-01-28T05:00:00',
    Direction: 'In',
    FlightClassCode: 'Economy',
    Passengers: passengerStubs,
    ServiceStatus: 'CON'
  },
  {
    DepartureAirportCode: 'PMI',
    DepartureCountryCode: 'ES',
    DestinationAirportCode: 'VGO',
    DestinationCountryCode: 'ES',
    IataFlightNumber: 2098,
    IataOperatingCarrierCode: 'DC',
    IataOperatingCarrierName: 'DC Airlines',
    DepartureDateTime: '2022-01-28T07:00:00',
    ArrivalDateTime: '2022-01-28T09:00:00',
    Direction: 'In',
    FlightClassCode: 'Economy',
    Passengers: passengerStubs,
    ServiceStatus: 'CON'
  },
  {
    DepartureAirportCode: 'VGO',
    DepartureCountryCode: 'ES',
    DestinationAirportCode: 'MAD',
    DestinationCountryCode: 'ES',
    IataFlightNumber: 102,
    IataOperatingCarrierCode: 'DC',
    IataOperatingCarrierName: 'DC Airlines',
    DepartureDateTime: '2022-01-28T10:00:00',
    ArrivalDateTime: '2022-01-28T18:00:00',
    Direction: 'In',
    FlightClassCode: 'Economy',
    Passengers: passengerStubs,
    ServiceStatus: 'CON'
  },
  {
    DepartureAirportCode: 'MAD',
    DepartureCountryCode: 'ES',
    DestinationAirportCode: 'FRA',
    DestinationCountryCode: 'DE',
    IataFlightNumber: 102,
    IataOperatingCarrierCode: 'DC',
    IataOperatingCarrierName: 'DC Airlines',
    DepartureDateTime: '2022-01-28T19:00:00',
    ArrivalDateTime: '2022-01-28T20:00:00',
    Direction: 'In',
    FlightClassCode: 'Economy',
    Passengers: passengerStubs,
    ServiceStatus: 'CON'
  }
];

export const unsortedArrivalFlightLegStubs: FlightLeg[] = [
  {
    ...arrivalflightLegStubs[0],
    ArrivalDateTime: '2022-01-21T20:05:00'
  },
  {
    ...arrivalflightLegStubs[1],
    ArrivalDateTime: '2022-01-21T23:00:00'
  },
  {
    ...arrivalflightLegStubs[2],
    ArrivalDateTime: '2022-01-21T18:00:00'
  }
];

export const unsortedDepartureFlightLegStubs: FlightLeg[] = [
  {
    ...departureflightLegStubs[0],
    DepartureDateTime: '2022-01-28T02:00:00'
  },
  {
    ...departureflightLegStubs[1],
    DepartureDateTime: '2022-01-28T10:00:00'
  },
  {
    ...departureflightLegStubs[2],
    DepartureDateTime: '2022-01-28T07:00:00'
  }
];
