import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { InstanaService } from '../../services/instana.service';
@Component({
  selector: 'app-instana',
  templateUrl: './instana.component.html',
  styleUrls: ['./instana.component.scss']
})
export class InstanaComponent implements OnInit {
  constructor(private readonly instanaService: InstanaService) {}

  ngOnInit() {
    if (environment.instanaKey !== '') {
      this.appendInstanaScript();
      this.appendInstanaFunctionScript();
      this.instanaService.init(environment.instanaKey);
    }
  }

  private appendInstanaScript() {
    const body = document.body as HTMLDivElement;
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://eum.instana.rewe.de/eum/eum.min.js';
    script.crossOrigin = 'anonymous';
    body.appendChild(script);
  }

  private appendInstanaFunctionScript() {
    const body = document.body as HTMLDivElement;
    const script = document.createElement('script');
    script.innerHTML =
      '(function(c, g, h, b) {c[g] ||((c[g] = h),(b = c[h] = function() {b.q.push(arguments);}),(b.q = []),(b.l = 1 * new Date()));})(window, "InstanaEumObject", "ineum");';
    body.appendChild(script);
  }
}
