import { ToastActions } from '@app/modules/core/actions/toast.action';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { DsaToastService } from '@dsa/design-system-angular';

@Injectable()
export class ToastEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly toastService: DsaToastService
  ) {}

  showInfoToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ToastActions.infoToast),
        tap(_ => this.toastService.info(_.message, _.timeout))
      ),
    { dispatch: false }
  );

  showSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ToastActions.successToast),
        tap(_ => this.toastService.success(_.message, _.timeout))
      ),
    { dispatch: false }
  );

  showWarningToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ToastActions.warningToast),
        tap(_ => this.toastService.warning(_.message, _.timeout))
      ),
    { dispatch: false }
  );

  showErrorToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ToastActions.errorToast),
        tap(_ => this.toastService.error(_.message, _.timeout))
      ),
    { dispatch: false }
  );
}
