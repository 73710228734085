import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on
} from '@ngrx/store';
import { JobNotificationActions } from '../actions/job-notification.actions';
import { JobNotification } from '../data/job-notification';

export const featureKey = 'job-notifications';

export interface State {
  notifications: JobNotification[];
}

export const initialState: State = {
  notifications: []
};

export const reducers = createReducer(
  initialState,

  on(JobNotificationActions.receive, (state, { notification }) => ({
    notifications: [notification].concat(state.notifications)
  })),

  on(JobNotificationActions.read, state => ({
    notifications: state.notifications.map(n => {
      return {
        ...n,
        isNew: false
      };
    })
  })),

  on(JobNotificationActions.remove, (state, { notification }) => ({
    notifications: state.notifications.filter(x => x.id !== notification.id)
  }))
);

export const selectNotifications = createSelector(
  createFeatureSelector<State>(featureKey),
  (state: State) => state.notifications
);
