export interface StandardMemo {
  Code: string;
  CodeName: string;
  Description: string;
}

export const standardMemoStubs: StandardMemo[] = [
  {
    Code: 'MM',
    CodeName: 'Manual Memo',
    Description: 'Kundenwunsch: Baby-Reisebett'
  },
  {
    Code: 'BD',
    CodeName: 'Booking Details',
    Description:
      'Instructors wish room facing mog if possible, same as in april:)'
  }
];
