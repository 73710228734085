export interface CaptureDetail {
  Code: string;
  Name: string;
  Value: string;
  PassengerId: string;
}

export const captureDetailStubs: CaptureDetail[] = [
  {
    Code: '120ROOM',
    Name: 'Schuhgroeße',
    Value: '37',
    PassengerId: '702414'
  },
  {
    Code: '121ROOM',
    Name: 'Hutgroeße',
    Value: '40',
    PassengerId: '702415'
  }
];

export const mixedCaptureDetailStubs: CaptureDetail[] = [
  {
    Code: '120ROOM',
    Name: 'Hemdgroeße',
    Value: '48',
    PassengerId: '702416'
  },
  {
    Code: '121ROOM',
    Name: 'Hosengroeße',
    Value: '32',
    PassengerId: null
  }
];
