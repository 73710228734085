import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  DsaBadgeModule,
  DsaCardModule,
  DsaInfoCardModule
} from '@dsa/design-system-angular';
import { DsaTooltipModule } from '@dsa/design-system-angular/tooltip';
import { StoreModule } from '@ngrx/store';
import { JobNotificationsDisplayComponent } from './components/job-notifications-display/job-notifications-display.component';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import * as fromNotifications from './reducers/job-notifications.reducer';
import { DsaIconModule } from '@dsa/design-system-angular/icon';

@NgModule({
  declarations: [JobNotificationsDisplayComponent],
  imports: [
    CommonModule,
    DsaBadgeModule,
    DsaCardModule,
    DsaIconModule,
    DsaInfoCardModule,
    DsaTooltipModule,
    StoreModule.forFeature(
      fromNotifications.featureKey,
      fromNotifications.reducers
    ),
    TimeAgoPipe
  ],
  exports: [JobNotificationsDisplayComponent]
})
export class JobNotificationsDisplayModule {}
