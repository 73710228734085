import { Injectable } from '@angular/core';
import {
  EntityCollectionDataService,
  HttpUrlGenerator,
  DefaultDataServiceConfig
} from '@ngrx/data';
import { CoreDataService } from './core-data-service';
import { HttpClient } from '@angular/common/http';
import { ApiEndpointsService } from '../services/api-endpoints.service';

@Injectable()
export class CoreDataServiceFactory {
  constructor(
    private readonly http: HttpClient,
    private readonly urlGenerator: HttpUrlGenerator,
    private readonly apiEndpointsService: ApiEndpointsService,
    private readonly config?: DefaultDataServiceConfig
  ) {}

  create<T>(entityName: string): EntityCollectionDataService<T> {
    return new CoreDataService<T>(
      entityName,
      this.http,
      this.urlGenerator,
      this.apiEndpointsService,
      this.config
    );
  }
}
