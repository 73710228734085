import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { SuggesterActions } from '../actions/bookingexceptionsuggester.actions';
import { ExceptionSuggesterService } from '../services/bookingexception-suggester.service';

@Injectable()
export class SuggestEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly suggester: ExceptionSuggesterService
  ) {}

  onSuggest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuggesterActions.suggestRequest),
      switchMap(req =>
        this.suggester
          .searchSuggestions(req.searchString, req.searchField)
          .pipe(
            map(suggestions =>
              SuggesterActions.suggestSuccess({
                searchField: req.searchField,
                suggestions: suggestions
              })
            )
          )
      )
    )
  );
}
