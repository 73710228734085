import {
  DefaultDataService,
  HttpUrlGenerator,
  DefaultDataServiceConfig,
  HttpMethods
} from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiEndpointsService } from '../services/api-endpoints.service';

export class CoreDataService<T> extends DefaultDataService<T> {
  constructor(
    entityName: string,
    protected httpClient: HttpClient,
    protected urlGenerator: HttpUrlGenerator,
    private readonly apiEndpointsService: ApiEndpointsService,
    config?: DefaultDataServiceConfig
  ) {
    super(entityName, httpClient, urlGenerator, config);
    this._name = `${entityName} CoreDataService`;
  }

  protected execute(
    method: HttpMethods,
    url: string,
    data?: any,
    options?: any
  ): Observable<any> {
    url = this.adjustEndpoint(url);

    return super.execute(method, url, data, options);
  }

  private adjustEndpoint(url: string): string {
    const endpoint = this.apiEndpointsService.get(this.entityName);
    if (endpoint) {
      url = endpoint + url;
    }

    return url;
  }
}
