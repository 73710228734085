import { createAction, props } from '@ngrx/store';
import { HotelChainDropdown } from '../../shared/data/hotel-chain-dropdown';

const get = createAction('[BusinessException] Get HotelChain Dropdown');
const getSuccess = createAction(
  '[BusinessException] Get HotelChain Dropdown successfully',
  props<{
    hotelChainDropdown: HotelChainDropdown[];
  }>()
);

export const HotelChainDropdownActions = {
  get,
  getSuccess
};
