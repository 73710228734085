export interface OfferCode {
  Offer: string;
}

export const offerCodeStubs: OfferCode[] = [
  {
    Offer: 'EBO 20'
  },
  {
    Offer: 'EBO 40'
  }
];
