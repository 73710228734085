import { Passenger, passengerStubs } from './passenger';

export interface Transfer {
  Name: string;
  Code: string;
  Passengers: Passenger[];
  ServiceStatus: null | 'CON' | 'REQ' | 'CNX';
  NotificationMethod: null | 'Online' | 'File' | 'Mail';
  NotificiationRecipient: string;
  StartDate: string;
  AdvisingVia: string;
}

export const transferStubs: Transfer[] = [
  {
    Name: 'In Bus Sunny Beach',
    Code: 'VARIPS022',
    Passengers: passengerStubs,
    ServiceStatus: null,
    NotificationMethod: 'Online',
    NotificiationRecipient: 'CORE',
    StartDate: '2022-01-21T05:00:00',
    AdvisingVia: 'external Mail'
  },
  {
    Name: 'Out Bus Sunny Beach',
    Code: 'VAROPS022',
    Passengers: passengerStubs,
    NotificationMethod: 'Mail',
    NotificiationRecipient: 'CORE_AE',
    ServiceStatus: 'CNX',
    StartDate: '2022-01-27T08:00:00',
    AdvisingVia: 'DTS (CORE)'
  }
];

export const transferWithNoStartDateStubs: Transfer[] = [
  {
    Name: 'In Bus Sunny Beach',
    Code: 'VARIPS022',
    Passengers: passengerStubs,
    NotificationMethod: 'Online',
    NotificiationRecipient: 'CORE_AE',
    ServiceStatus: null,
    StartDate: null,
    AdvisingVia: 'external File'
  },
  {
    Name: 'Out Bus Sunny Beach',
    Code: 'VAROPS022',
    Passengers: passengerStubs,
    NotificationMethod: 'Mail',
    NotificiationRecipient: 'CORE_DVR',
    ServiceStatus: 'CNX',
    StartDate: null,
    AdvisingVia: 'DTS (CORE)'
  }
];

export const departureTransferWithNoStartDateStubs: Transfer[] = [
  {
    Name: 'In Bus Sunny Beach',
    Code: 'VAROPS022',
    Passengers: passengerStubs,
    NotificationMethod: 'File',
    NotificiationRecipient: 'CORE_AE',
    ServiceStatus: null,
    StartDate: null,
    AdvisingVia: 'external File'
  },
  {
    Name: 'Out Bus Sunny Beach',
    Code: 'VAROPS022',
    Passengers: passengerStubs,
    NotificationMethod: 'Mail',
    NotificiationRecipient: 'CORE_DVR',
    ServiceStatus: 'CNX',
    StartDate: null,
    AdvisingVia: 'external Mail'
  }
];
