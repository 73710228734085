import { createAction, props } from '@ngrx/store';

const infoToast = createAction(
  '[Core - Toast] Show info toast.',
  props<{ message: string; timeout?: number }>()
);

const successToast = createAction(
  '[Core - Toast] Show success toast.',
  props<{ message: string; timeout?: number }>()
);

const warningToast = createAction(
  '[Core - Toast] Show warning toast.',
  props<{ message: string; timeout?: number }>()
);

const errorToast = createAction(
  '[Core - Toast] Show error toast.',
  props<{ message: string; timeout?: number }>()
);

export const ToastActions = {
  infoToast,
  successToast,
  warningToast,
  errorToast
};
