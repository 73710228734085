<div class="information-tab">
  <div class="scrollableMemoContent">
    <ng-container *ngFor="let memo of memos; let i = index">
      <dsa-object-header
        class="dsaObjectHeaderStyle"
        [isExpanded]="true"
        [onlyExtended]="true"
      >
        <h3>{{ i + 1 }} - {{ memo.CodeName }} - {{ memo.Code }}</h3>
        <ul>
          <li>
            <span>Description:</span>
            <span>{{ memo.Description || "-" }}</span>
          </li>
        </ul></dsa-object-header
      >
    </ng-container>
  </div>
</div>
