import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import { CoreActions } from '@app/modules/core/actions/core.actions';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard {
  private roles: string[];
  private loggedIn: boolean;

  constructor(private readonly store: Store<fromRoot.State>) {
    store.pipe(select(fromRoot.selectAuthState)).subscribe(authInfo => {
      this.loggedIn = authInfo.loggedIn;
      this.roles = authInfo.roles;
    });
  }

  canActivate(next: ActivatedRouteSnapshot, _: RouterStateSnapshot): boolean {
    return this.hasAccess(next);
  }

  private hasAccess(next: ActivatedRouteSnapshot) {
    if (!this.loggedIn) {
      this.store.dispatch(CoreActions.userLogin());
      return false;
    }

    if (
      next.data &&
      next.data.allowedRoles &&
      !this.isInRole(next.data.allowedRoles)
    ) {
      this.store.dispatch(CoreActions.accessDenied());
      return false;
    }
    return true;
  }

  private isInRole(allowedRoles: string[]) {
    return allowedRoles.some(r => this.roles.includes(r));
  }
}
