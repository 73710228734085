<app-header></app-header>
<ng-container *ngIf="(authState$ | async)?.loggedIn">
  <app-service-desk-feedback></app-service-desk-feedback>
</ng-container>

<div fxLayout="row" fxLayoutAlign="space-around center">
  <dsa-spinner *ngIf="loadingFeatureModule$ | async"></dsa-spinner>
</div>

<div class="dsa-content-fullscreen">
  <router-outlet></router-outlet>
</div>

<dsa-toast-panel></dsa-toast-panel>

<dsa-back-to-top-button></dsa-back-to-top-button>
<dsa-footer [copyrightText]="copyright" [brand]="brand">
  <ng-template dsaFooterRightOutlet
    ><a href="#" target="_blank">New Tab</a></ng-template
  >
</dsa-footer>

<app-instana></app-instana>
