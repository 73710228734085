import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiEndpointsService } from '../../core/services/api-endpoints.service';
import { HotelChainDropdown } from '../../shared/data/hotel-chain-dropdown';

@Injectable({
  providedIn: 'root'
})
export class HotelChainDropdownService {
  constructor(
    private readonly http: HttpClient,
    private readonly api: ApiEndpointsService
  ) {}

  get(): Observable<HotelChainDropdown[]> {
    return this.http.get<HotelChainDropdown[]>(
      this.api.endpoints.dynamicConnect + '/HotelChain/Dropdown'
    );
  }
}
