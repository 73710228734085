export interface ValueAddedOffer {
  Id: string;
  Code: string;
  Text: string;
}

export const valueAddedOfferStubs: ValueAddedOffer[] = [
  {
    Id: '123456789',
    Code: 'FRBK',
    Text: 'Fruit basket'
  },
  {
    Id: '987654321',
    Code: 'CHMP',
    Text: 'Bottle of champagne'
  }
];

export const mixedValueAddedOfferStubs: ValueAddedOffer[] = [
  {
    Id: '123456321',
    Code: 'PRWA',
    Text: 'Premium Water'
  },
  {
    Id: '987654123',
    Code: 'COLA',
    Text: null
  }
];
