import { createAction, props } from '@ngrx/store';
import { FlightInfo } from '../../shared/data/flight-info';
import { RequestMemos } from '../data/request-memos';

const getFlightInfos = createAction(
  '[Flight Infos] Get Flight Infos.',
  props<{
    tourOperator: string;
    externalReservationNumber: string;
    version: string;
  }>()
);

const getMemosSuccess = createAction(
  '[Flight Infos] Get Memos Success.',
  props<{
    memos: RequestMemos;
  }>()
);

const getFlightsSuccess = createAction(
  '[Flight Infos] Get Flights Success.',
  props<{
    flightInfos: FlightInfo[];
  }>()
);

const clearFlightInfosStore = createAction(
  '[Flight Infos] Clear Flight Infos Store.'
);

export const FlightInfoActions = {
  getFlightInfos,
  getMemosSuccess,
  getFlightsSuccess,
  clearFlightInfosStore: clearFlightInfosStore
};
