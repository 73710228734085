<div class="dialogContent">
  <div fxLayout="row" fxLayoutAlign="center">
    <dsa-spinner
      *ngIf="!((requestEntity$ | async) || (error$ | async))"
    ></dsa-spinner>
  </div>
  <mat-dialog-content
    *ngIf="
      (requestEntity$ | async) && !isCoreConsumerMessage(requestEntity$ | async)
    "
    class="scrollableMemoContent"
  >
    <ngx-json-viewer [json]="requestEntity$ | async"></ngx-json-viewer>
  </mat-dialog-content>
  <ng-container *ngIf="requestEntity$ | async as requestEntity">
    <div *ngIf="isCoreConsumerMessage(requestEntity) && !(error$ | async)">
      <dsa-object-header
        id="StaticViewHeader"
        [isExpanded]="true"
        [onlyExtended]="true"
      >
        <h3>
          Booking {{ requestEntity.externalReservationNumber }} ({{
            requestEntity.tourOperatorCode
          }})
        </h3>
        <ul>
          <li>
            <span>Booking/Reservation Status:</span>
            <span id="bookingReservationStatus"
              >{{ requestEntity.request.Booking.BookingStatus || "-" }}/{{
                requestEntity.request.Booking.ReservationStatus || "-"
              }}
            </span>
            <span>Created Date:</span>
            <span>{{
              (requestEntity.request.Booking.CreatedDateTime
                | date : "dd/MM/yyyy HH:mm") || "-"
            }}</span>
          </li>
        </ul>
        <ul>
          <li>
            <span>Booking Version:</span>
            <span>{{ requestEntity.externalVersion || "-" }}</span>
            <span>Confirmed Date:</span>
            <span>{{
              (requestEntity.request.Booking.BookingConfirmedDateTime
                | date : "dd/MM/yyyy HH:mm") || "-"
            }}</span>
          </li>
        </ul>
        <ul>
          <li>
            <span>Product Code:</span>
            <span>{{ requestEntity.request.Booking.ProductCode || "-" }}</span>
            <span>Cancelled Date:</span>
            <span>{{
              (requestEntity.request.Booking.CancelledDateTime
                | date : "dd/MM/yyyy HH:mm") || "-"
            }}</span>
          </li>
        </ul>
        <ul>
          <li>
            <span>Product Name:</span>
            <span>{{ requestEntity.request.Booking.ProductName || "-" }}</span>
            <span>Travel Period:</span>
            <span id="travelPeriod"
              >{{ (travelPeriodStart | date : "dd/MM/yyyy HH:mm") || "-" }} -
              {{ (travelPeriodEnd | date : "dd/MM/yyyy HH:mm") || "-" }}</span
            >
          </li>
        </ul>
      </dsa-object-header>
      <dsa-tab-group>
        <dsa-tab label="Hotels" id="hotelInformationTab">
          <dsa-content class="dsaTabClass"
            ><app-hotelinformation-tab
              [hotelStays]="requestEntity.request.Booking.HotelStays"
            ></app-hotelinformation-tab
          ></dsa-content>
        </dsa-tab>
        <dsa-tab label="Passengers" id="passengerInformationTab">
          <dsa-content class="dsaTabClass"
            ><app-passenger-information-tab
              [passengers]="requestEntity.request.Booking.Passengers"
            ></app-passenger-information-tab
          ></dsa-content>
        </dsa-tab>
        <dsa-tab label="Flights" id="flightInformationTab">
          <dsa-content class="dsaTabClass"
            ><app-flightinformation-tab
              [flights]="requestEntity.request.Booking.Flights"
            ></app-flightinformation-tab
          ></dsa-content>
        </dsa-tab>
        <dsa-tab label="Transfers" id="transferInformationTab">
          <dsa-content class="dsaTabClass"
            ><app-transferinformation-tab
              [transfers]="requestEntity.request.Booking.Transfers"
            ></app-transferinformation-tab
          ></dsa-content>
        </dsa-tab>
        <dsa-tab label="Booking Memos" id="bookingMemosTab">
          <dsa-content class="dsaTabClass"
            ><app-bookingmemos-tab
              [memos]="requestEntity.request.Booking.BookingMemos"
            ></app-bookingmemos-tab
          ></dsa-content>
        </dsa-tab>
      </dsa-tab-group>
    </div>
  </ng-container>
</div>
<div #errorMessage *ngIf="error$ | async">Something went wrong.</div>
<mat-divider class="marginTopDivider"></mat-divider>
<div mat-dialog-actions style="padding-right: 24px">
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="11px" fxFill>
    <button
      *ngIf="requestEntity$ | async as requestEntity"
      dsa-button
      (click)="download(requestEntity)"
    >
      Download
    </button>
    <button dsa-button (click)="close()">Close</button>
  </div>
</div>
