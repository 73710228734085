import {
  CaptureDetail,
  captureDetailStubs,
  mixedCaptureDetailStubs
} from './capture-detail';
import { OfferCode, offerCodeStubs } from './offer-codes';
import { mixedPassengerStubs, Passenger, passengerStubs } from './passenger';
import {
  mixedValueAddedOfferStubs,
  ValueAddedOffer,
  valueAddedOfferStubs
} from './value-added-offer';

export interface Room {
  RoomTypeName: string;
  RoomTypeCode: string;
  RoomCategoryName: string;
  RoomDescription: string;
  BoardBasisName: string;
  BoardBasisCode: string;
  NoOfPaxTotal: number;
  NoOfPaxAdult: number;
  NoOfPaxInfant: number;
  NoOfPaxChild: number;
  Passengers: Passenger[];
  OfferCodes: OfferCode[];
  ValueAddedOffers: ValueAddedOffer[];
  CaptureDetails: CaptureDetail[];
  TotalCost: number;
  TotalCostCurrencyCode: string;
}

export const emptyRoomStub: Room = {
  RoomTypeName: '',
  RoomTypeCode: '',
  RoomCategoryName: '',
  RoomDescription: null,
  BoardBasisName: '',
  BoardBasisCode: '',
  NoOfPaxTotal: 0,
  NoOfPaxAdult: 0,
  NoOfPaxInfant: 0,
  NoOfPaxChild: 0,
  Passengers: [],
  OfferCodes: [],
  ValueAddedOffers: [],
  CaptureDetails: [],
  TotalCost: 0,
  TotalCostCurrencyCode: 'EUR'
};

export const roomStubs: Room[] = [
  {
    RoomTypeName: 'Double Room',
    RoomTypeCode: 'DB1',
    RoomCategoryName: 'Double',
    RoomDescription: 'Dirty',
    BoardBasisName: 'All Inclusive',
    BoardBasisCode: 'AI',
    NoOfPaxTotal: 2,
    NoOfPaxAdult: 2,
    NoOfPaxInfant: 0,
    NoOfPaxChild: 0,
    Passengers: passengerStubs,
    OfferCodes: offerCodeStubs,
    ValueAddedOffers: valueAddedOfferStubs,
    CaptureDetails: captureDetailStubs,
    TotalCost: 934.42,
    TotalCostCurrencyCode: 'EUR'
  },
  {
    RoomTypeName: 'Double Room Deluxe',
    RoomTypeCode: 'DBD1',
    RoomCategoryName: 'Double',
    RoomDescription: 'Clean',
    BoardBasisName: 'All Inclusive Plus',
    BoardBasisCode: 'AIP',
    NoOfPaxTotal: 4,
    NoOfPaxAdult: 2,
    NoOfPaxInfant: 1,
    NoOfPaxChild: 1,
    Passengers: mixedPassengerStubs,
    OfferCodes: offerCodeStubs,
    ValueAddedOffers: mixedValueAddedOfferStubs,
    CaptureDetails: mixedCaptureDetailStubs,
    TotalCost: 922.42,
    TotalCostCurrencyCode: 'EUR'
  }
];
