import { createAction, props } from '@ngrx/store';

const loadingLazyModuleStarted = createAction(
  '[Core] Loading lazy module started'
);

const loadingLazyModuleFinished = createAction(
  '[Core] Loading lazy module finished'
);

const userLogin = createAction('[Core - oAuth] Start user login');

const userLogout = createAction('[Core - oAuth] Start user logout');

const userLoggedIn = createAction('[Core - oAuth] Service logged in');

const updateAuthState = createAction(
  '[Core - oAuth] Auth state updated',
  props<{
    loggedIn: boolean;
    token: string;
    roles: string[];
    tenants: string[];
    username: string;
    givenname: string;
    familyname: string;
  }>()
);

const accessDenied = createAction(
  '[Core - oAuth] Access denied. Permissions Missing.'
);

const switchingAppNavMenu = createAction(
  '[Core] Switching app menu',
  props<{ activeModule: string }>()
);

export const CoreActions = {
  loadingLazyModuleStarted,
  loadingLazyModuleFinished,
  switchingAppNavMenu,
  updateAuthState,
  userLogin,
  userLogout,
  accessDenied,
  userLoggedIn
};
