import { Component, Input } from '@angular/core';
import { StandardMemo } from '../../data/core-consumer-message/standard-memo';

@Component({
  selector: 'app-bookingmemos-tab',
  templateUrl: './bookingmemos-tab.component.html',
  styleUrls: [
    '../bookingviewer/bookingviewer.subcomponent.scss',
    './bookingmemos-tab.component.scss'
  ]
})
export class BookingmemosTabComponent {
  @Input() public memos: StandardMemo[];
}
