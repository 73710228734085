export interface Passenger {
  PassengerId: string;
  PassengerSequence: number;
  FirstName: string;
  LastName: string;
  Gender: string;
  BirthDate: string;
  Age: number;
  PassengerType: 'Adult' | 'Child' | 'Infant';
}

export const passengerStubs: Passenger[] = [
  {
    PassengerId: '702414',
    PassengerSequence: 1,
    FirstName: 'Florian',
    LastName: 'Wolf',
    Gender: 'Male',
    BirthDate: '1987-11-13T00:00:00',
    Age: 34,
    PassengerType: 'Adult'
  },
  {
    PassengerId: '702415',
    PassengerSequence: 2,
    FirstName: 'Anna',
    LastName: 'Wolf',
    Gender: 'Female',
    BirthDate: '1988-11-05T00:00:00',
    Age: 33,
    PassengerType: 'Adult'
  }
];

export const mixedPassengerStubs: Passenger[] = [
  {
    PassengerId: '702416',
    PassengerSequence: 1,
    FirstName: 'Thomas',
    LastName: 'Wolf',
    Gender: 'Male',
    BirthDate: '1987-11-13T00:00:00',
    Age: 34,
    PassengerType: 'Adult'
  },
  {
    PassengerId: '702417',
    PassengerSequence: 2,
    FirstName: 'Brigitte',
    LastName: 'Wolf',
    Gender: 'Female',
    BirthDate: '1988-11-05T00:00:00',
    Age: 33,
    PassengerType: 'Adult'
  }
];
