import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiEndpointsService } from '../../../../core/services/api-endpoints.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReceivedService {
  constructor(
    private readonly http: HttpClient,
    private readonly apiEndpoints: ApiEndpointsService
  ) {}

  get(externalReservationNumber: string) {
    const url = `${this.apiEndpoints.endpoints.requests}/${externalReservationNumber}`;

    return this.http
      .get<any>(url, {
        observe: 'response',
        responseType: 'json'
      })
      .pipe(map(resp => resp.body));
  }
}
