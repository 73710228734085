import { createAction, props } from '@ngrx/store';
import { JobNotification } from '../data/job-notification';

const receive = createAction(
  '[Notifications] Receive',
  props<{
    notification: JobNotification;
  }>()
);

const read = createAction('[Notifications] Read');

const remove = createAction(
  '[Notifications] Remove',
  props<{
    notification: JobNotification;
  }>()
);

export const JobNotificationActions = {
  receive: receive,
  read: read,
  remove: remove
};
