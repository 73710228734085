import { createReducer, on } from '@ngrx/store';
import { BookingActions } from '../actions/booking.actions';
import { Booking } from '../data/core-consumer-message/booking';
import { CoreConsumerMessage } from '../data/core-consumer-message/core-consumer-message';
import { Flight } from '../data/core-consumer-message/flight';
import { FlightLeg } from '../data/core-consumer-message/flight-leg';
import { HotelStay } from '../data/core-consumer-message/hotel-stay';
import { Passenger } from '../data/core-consumer-message/passenger';
import { Transfer } from '../data/core-consumer-message/transfer';
import { isCoreConsumerMessage, RequestEntity } from '../data/request-entity';

export const featureKey = 'requestbooking';

export interface State {
  booking: RequestEntity<CoreConsumerMessage> | RequestEntity<any>;
  error: any;
}

export const initialState: State = {
  booking: null,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(BookingActions.getBookingSuccess, (state, { booking }) => ({
    ...state,
    booking: isCoreConsumerMessage(booking)
      ? expandPassengerIds(booking)
      : booking
  })),
  on(BookingActions.getBookingFailure, (state, { error }) => ({
    ...state,
    error: error
  })),
  on(BookingActions.clearBookingStore, state => ({
    ...state,
    booking: initialState.booking,
    error: initialState.error
  }))
);

function expandPassengerIds(
  entity: RequestEntity<CoreConsumerMessage>
): RequestEntity<CoreConsumerMessage> {
  const bookingPax = new Map<string, Passenger>(
    entity.request.Booking.Passengers.map(p => [p.PassengerId, p])
  );

  return {
    ...entity,
    request: {
      Booking: {
        ...entity.request.Booking,
        Flights: sortFlightsByTime(
          sortFlightLegsByTime(
            expandFlightPassengerIds(bookingPax, entity.request.Booking.Flights)
          )
        ),
        HotelStays: expandRoomPassengerIds(
          bookingPax,
          entity.request.Booking.HotelStays
        ),
        Transfers: expandTransferPassengerIds(
          entity,
          bookingPax,
          entity.request.Booking.Transfers
        )
      }
    }
  };
}

function expandFlightPassengerIds(
  bookingPax: Map<string, Passenger>,
  flights: Flight[]
): Flight[] {
  return flights.map(flight => ({
    ...flight,
    FlightLegs: flight.FlightLegs.map(flightLeg => ({
      ...flightLeg,
      Passengers: flightLeg.Passengers.map(p => bookingPax.get(p.PassengerId))
    }))
  }));
}

function expandRoomPassengerIds(
  bookingPax: Map<string, Passenger>,
  hotelStays: HotelStay[]
): HotelStay[] {
  return hotelStays.map(hotelStay => ({
    ...hotelStay,
    Rooms: hotelStay.Rooms.map(room => ({
      ...room,
      Passengers: room.Passengers.map(p => bookingPax.get(p.PassengerId))
    })),
    Source: getSourceFromHotelStay(hotelStay),
    AdvisingVia: setAdvisingVia(hotelStay)
  }));
}

function expandTransferPassengerIds(
  booking: RequestEntity<CoreConsumerMessage>,
  bookingPax: Map<string, Passenger>,
  transfers: Transfer[]
): Transfer[] {
  const transferWithStartDate = createTransferDate(booking, transfers);
  return transferWithStartDate.map(transfer => ({
    ...transfer,
    Passengers: transfer.Passengers.map(p => bookingPax.get(p.PassengerId)),
    AdvisingVia: setAdvisingVia(transfer)
  }));
}

function getSourceFromHotelStay(hotelStay: any): string {
  let result: string;

  if (hotelStay.ExternalSupplierName) {
    result = `dynamic ${hotelStay.ExternalSupplierName}`;
  } else {
    result = 'Internal/allotment';
  }
  return result;
}

function setAdvisingVia(source: any): string {
  const recipientPattern: string[] = ['CORE', 'CORE_AE', 'CORE_DVR'];
  let advisingVia: string;

  if (recipientPattern.includes(source.NotificationRecipient)) {
    advisingVia = 'DTS (CORE)';
  } else if (source.NotificationRecipient) {
    advisingVia = `external (${source.NotificationMethod})`;
  } else {
    advisingVia = '-';
  }
  return advisingVia;
}

function sortFlightLegsByTime(flights: Flight[]): Flight[] {
  return flights.map(f => {
    const flightLegs = [...f.FlightLegs];
    flightLegs.sort((a, b) =>
      a.Direction === 'Out'
        ? Date.parse(a.ArrivalDateTime) - Date.parse(b.ArrivalDateTime)
        : Date.parse(a.DepartureDateTime) - Date.parse(b.DepartureDateTime)
    );
    return {
      ...f,
      FlightLegs: flightLegs
    };
  });
}

function sortFlightsByTime(flights: Flight[]): Flight[] {
  const sortedFlights = [...flights];
  sortedFlights.sort((a, b) => {
    if (
      a.FlightLegs[0].Direction === 'Out' &&
      b.FlightLegs[0].Direction === 'Out'
    ) {
      return (
        Date.parse(a.FlightLegs.at(-1).ArrivalDateTime) -
        Date.parse(b.FlightLegs.at(-1).ArrivalDateTime)
      );
    } else if (
      a.FlightLegs[0].Direction === 'In' &&
      b.FlightLegs[0].Direction === 'In'
    ) {
      return (
        Date.parse(a.FlightLegs[0].DepartureDateTime) -
        Date.parse(b.FlightLegs[0].DepartureDateTime)
      );
    } else if (
      a.FlightLegs[0].Direction === 'Out' &&
      b.FlightLegs[0].Direction === 'In'
    ) {
      return -1;
    } else {
      return 1;
    }
  });
  return sortedFlights;
}

function createTransferDate(
  booking: RequestEntity<CoreConsumerMessage>,
  transfers: Transfer[]
): Transfer[] {
  const transfersClone = transfers.map(x => Object.assign({}, x));
  if (booking.tourOperatorCode == 'DTD') {
    transfersClone.forEach(t => {
      t.StartDate = getTransferDate(booking.request.Booking, t);
    });
  }
  return transfersClone;
}

function getTransferDate(booking: Booking, transfer: Transfer): string {
  if (
    transfer.StartDate != null &&
    transfer.StartDate !== '0001-01-01T00:00:00'
  ) {
    return transfer.StartDate;
  }

  if (booking.Flights && booking.Flights.length !== 0) {
    return getStartDateFromFlights(booking.Flights, transfer);
  }

  if (booking.HotelStays && booking.HotelStays.length !== 0) {
    return getStartDateFromHotelStays(booking.HotelStays, transfer);
  }

  return null;
}

function getStartDateFromFlights(
  flights: Flight[],
  transfer: Transfer
): string {
  if (transfer.Code.charAt(3) === 'I') {
    const arrivalFlight = getCorrespondingFlight(flights, transfer, 'Out');
    return arrivalFlight.ArrivalDateTime;
  } else {
    const departureFlight = getCorrespondingFlight(flights, transfer, 'In');
    return departureFlight.DepartureDateTime;
  }
}

function getCorrespondingFlight(
  flights: Flight[],
  transfer: Transfer,
  direction: string
): FlightLeg {
  const flightLegsWithDirection = flights
    .flatMap(f => f.FlightLegs)
    .filter(fl => fl.Direction === direction);
  return flightLegsWithDirection.find(fl =>
    haveOverlappingPax(transfer.Passengers, fl.Passengers)
  );
}

function haveOverlappingPax(paxA: Passenger[], paxB: Passenger[]) {
  return hasIntersection(
    paxA.map(p => p.PassengerId),
    paxB.map(p => p.PassengerId)
  );
}

function hasIntersection<T>(a: T[], b: T[]): boolean {
  return a.some(x => b.includes(x));
}

function getStartDateFromHotelStays(
  hotelStays: HotelStay[],
  transfer: Transfer
): string {
  const hotelStay = hotelStays.find(hs => {
    const hotelStayPasengers = hs.Rooms.flatMap(r => r.Passengers);
    return haveOverlappingPax(transfer.Passengers, hotelStayPasengers);
  });

  return hotelStay.CheckInDate;
}
