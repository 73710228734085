import { createAction, props } from '@ngrx/store';
import { RequestEntity } from '../data/request-entity';
import { CoreConsumerMessage } from '../data/core-consumer-message/core-consumer-message';

const getBooking = createAction(
  '[Booking] Get Booking.',
  props<{
    tourOperator: string;
    externalReservationNumber: string;
    version: string;
  }>()
);

const getBookingSuccess = createAction(
  '[Booking] Get Booking Success.',
  props<{
    booking: RequestEntity<CoreConsumerMessage> | RequestEntity<any>;
  }>()
);

const getBookingFailure = createAction(
  '[Booking] Get Booking Failure.',
  props<{ error: any }>()
);

const clearBookingStore = createAction('[Booking] Clear Booking Store.');

export const BookingActions = {
  getBooking,
  getBookingSuccess,
  getBookingFailure,
  clearBookingStore
};
