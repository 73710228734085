<div fxLayout="row" fxLayoutGap="10px">
  <div fxLayout="column wrap" fxLayout.xs="column" fxLayoutGap="20px grid">
    <div ngClass.gt-xs="size-m" fxFlex.gt-xs="nogrow" fxFlex.xs="grow">
      <dsa-datepicker
        placeholder="{{ datePlaceholder }}"
        [formControl]="dateForm"
        [dateFormat]="locale.dateFormat"
        [locale]="locale"
        showButtonBar="true"
      ></dsa-datepicker>
    </div>
  </div>
  <div fxLayout="column wrap" fxLayout.xs="column" fxLayoutGap="20px grid">
    <div ngClass.gt-xs="size-m" fxFlex.gt-xs="nogrow" fxFlex.xs="grow">
      <dsa-timepicker [formControl]="timeForm"></dsa-timepicker>
    </div>
  </div>
</div>
