import {
  EntityCollectionReducerMethods,
  EntityCollection,
  EntityAction,
  EntityDefinition
} from '@ngrx/data';
export class AdditionalEntityCollectionReducerMethods<
  T
> extends EntityCollectionReducerMethods<T> {
  constructor(
    public entityName: string,
    public definition: EntityDefinition<T>
  ) {
    super(entityName, definition);
  }
  protected queryManySuccess(
    collection: EntityCollection<T>,
    action: EntityAction<T[]>
  ): EntityCollection<T> {
    const ec = super.queryManySuccess(collection, action);

    (ec as any).metadata = (action.payload as any).metadata;

    return ec;
  }

  protected queryAllSuccess(
    collection: EntityCollection<T>,
    action: EntityAction<T[]>
  ): EntityCollection<T> {
    const ec = super.queryAllSuccess(collection, action);

    (ec as any).metadata = (action.payload as any).metadata;

    return ec;
  }
}
