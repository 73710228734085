import { Component } from '@angular/core';
import { Brand, DER, DsaMenuService } from '@dsa/design-system-angular';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import packageInfo from '../../package.json';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { APPLICATION_MENU } from './app.commons';
import * as fromApp from './reducers';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  authState$: Observable<any>;
  loadingFeatureModule$: Observable<boolean>;
  copyright = `©DER Touristik Deutschland GmbH - ${environment.name} - v${packageInfo.version}`;
  brand: Brand = DER;
  constructor(
    store: Store<fromApp.State>,
    oauthService: OAuthService,
    menuService: DsaMenuService
  ) {
    this.loadingFeatureModule$ = store.pipe(
      select(fromApp.selectLoadingFeatureModuleState)
    );

    this.authState$ = store.pipe(select(fromApp.selectAuthState));

    oauthService.configure(environment.authConfig);
    oauthService.tokenValidationHandler = new JwksValidationHandler();
    oauthService.loadDiscoveryDocumentAndTryLogin();
    oauthService.setupAutomaticSilentRefresh();

    menuService.menu = APPLICATION_MENU;
  }
}
