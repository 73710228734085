import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiEndpointsService } from '../../core/services/api-endpoints.service';
import { ExternalMapping } from '../data/externalmapping';
import { FilterParams } from '../data/filterparam';
import { ListResult } from '../data/listResult';

@Injectable({
  providedIn: 'root'
})
export class ExternalMappingService {
  lastParams: FilterParams;

  constructor(
    private readonly http: HttpClient,
    private readonly api: ApiEndpointsService
  ) {}

  get(filterParams?: FilterParams): Observable<ListResult<ExternalMapping>> {
    filterParams = { ...this.lastParams, ...filterParams };
    const result = this.http.get<ListResult<ExternalMapping>>(
      this.api.endpoints.dynamicConnect + '/externalmapping/Get',
      {
        params: filterParams
      }
    );
    this.lastParams = filterParams;
    return result;
  }

  delete(externalMapping: ExternalMapping): Observable<string> {
    return this.http.delete<string>(
      this.api.endpoints.dynamicConnect + '/externalmapping/Delete',
      {
        body: externalMapping
      }
    );
  }

  upsert(externalMapping: ExternalMapping): Observable<string> {
    return this.http.post<string>(
      this.api.endpoints.dynamicConnect + '/externalmapping/Upsert',
      externalMapping
    );
  }
}

export class ExternalMappingServiceStub {
  /* istanbul ignore next */
  get(_filterParams?: FilterParams): Observable<ListResult<ExternalMapping>> {
    return of({} as ListResult<ExternalMapping>);
  }
  /* istanbul ignore next */
  upsert(_externalMapping: ExternalMapping): Observable<string> {
    return of('');
  }
  /* istanbul ignore next */
  delete(_externalMapping: ExternalMapping): Observable<string> {
    return of('');
  }
}
