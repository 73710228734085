import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { NavigationActions } from '../actions/navigation.actions';

@Injectable()
export class NavigationEffects {
  constructor(
    private readonly actions$: Actions,
    private location: Location
  ) {}

  onNavigateBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NavigationActions.navigateBack),
        tap(_ => this.location.back())
      ),
    { dispatch: false }
  );
}
