import { Injectable } from '@angular/core';
import { JwtToken } from '../data/jwt-token';
import { environment } from '../../../../environments/environment';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  getUserRoles(token: JwtToken): string[] {
    const clientSettings =
      token.resource_access[environment.authConfig.clientId];

    if (!clientSettings) {
      return [];
    }

    return clientSettings.roles;
  }

  getTenants(token: JwtToken): string[] {
    return token['realm_access'].roles
      .filter((role: string) => role.startsWith('Tenant_'))
      .map((role: string) => role.replace('Tenant_', ''))
      .sort();
  }

  getUsername(token: JwtToken): string {
    return token.preferred_username;
  }

  getGivenname(token: JwtToken): string {
    return token.given_name;
  }

  getFamilyname(token: JwtToken): string {
    return token.family_name;
  }

  getDecodedToken(tokenString: string): JwtToken {
    return jwtDecode(tokenString);
  }
}
