export interface KidsProduct {
  ItemCodeName: string;
}

export const kidsProductStubs: KidsProduct[] = [
  {
    ItemCodeName: 'kids'
  },
  {
    ItemCodeName: 'Trem'
  },
  {
    ItemCodeName: 'Barne'
  }
];

export const mixedKidsProductStubs: KidsProduct[] = [
  {
    ItemCodeName: 'Stol'
  },
  {
    ItemCodeName: 'Gnest'
  },
  {
    ItemCodeName: 'Prok'
  }
];
