import { createAction, props } from '@ngrx/store';

const openUploadFileDialog = createAction(
  '[File-Import] Open upload File dialog'
);
const closeUploadFileDialog = createAction(
  '[File-Import] Close upload File dialog'
);
const importFail = createAction(
  '[File-Import] List errors on upload',
  props<{
    errors: string;
  }>()
);

export const FileImportActions = {
  openUploadFileDialog,
  closeUploadFileDialog,
  importFail
};
