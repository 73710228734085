<div class="information-tab">
  <div class="scrollableMemoContent">
    <ng-container *ngFor="let passenger of passengers">
      <dsa-object-header
        class="dsaObjectHeaderStyle"
        [isExpanded]="true"
        [onlyExtended]="true"
      >
        <h3>
          {{ passenger.PassengerSequence }} - {{ passenger.FirstName }}
          {{ passenger.LastName }} ({{ passenger.PassengerType }})
        </h3>
        <ul>
          <li>
            <span>Gender:</span>
            <span>{{ passenger.Gender || "-" }}</span>
            <span>Birthdate:</span>
            <span
              >{{ (passenger.BirthDate | date: "dd/MM/yyyy") || "-" }} ({{
                passenger.Age || "-"
              }}
              years)</span
            >
          </li>
        </ul></dsa-object-header
      >
    </ng-container>
  </div>
</div>
