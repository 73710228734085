import { createAction, props } from '@ngrx/store';
import { FilterParams } from '../data/filterparam';
import { ExternalMapping } from '../data/externalmapping';
import { ListResult } from '../data/listResult';

const getExternalMappings = createAction(
  '[ExternalMapping] Get ExternalMappings',
  props<{ filterParams?: FilterParams }>()
);

const getExternalMappingsSuccess = createAction(
  '[ExternalMapping] Get ExternalMappings Success',
  props<{ listResult: ListResult<ExternalMapping> }>()
);

const deleteExternalMapping = createAction(
  '[ExternalMapping] Delete ExternalMapping',
  props<{ externalMapping: ExternalMapping }>()
);

const deleteExternalMappingSuccess = createAction(
  '[ExternalMapping] Delete ExternalMapping Success'
);

const selectExternalMapping = createAction(
  '[ExternalMapping] Select ExternalMapping',
  props<{ externalMapping: ExternalMapping }>()
);

const upsertExternalMapping = createAction(
  '[ExternalMapping] Upsert ExternalMapping',
  props<{ externalMapping: ExternalMapping }>()
);

const upsertExternalMappingSuccess = createAction(
  '[ExternalMapping] Upsert ExternalMapping Success'
);

const unselectExternalMapping = createAction(
  '[ExternalMapping] Unselect ExternalMapping'
);

export const ExternalMappingActions = {
  getExternalMappings,
  getExternalMappingsSuccess,
  deleteExternalMapping,
  deleteExternalMappingSuccess,
  selectExternalMapping,
  upsertExternalMapping,
  upsertExternalMappingSuccess,
  unselectExternalMapping
};
