import {
  Action,
  combineReducers,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import * as fromBooking from './booking.reducer';

export const featureKey = 'shared';

export const initialState = {
  [featureKey]: {
    [fromBooking.featureKey]: fromBooking.initialState
  }
};

export interface SharedState {
  [fromBooking.featureKey]: fromBooking.State;
}

export interface State extends fromRoot.State {
  [featureKey]: SharedState;
}

export function reducers(state: SharedState | undefined, action: Action) {
  return combineReducers({
    [fromBooking.featureKey]: fromBooking.reducer
  })(state, action);
}

export const selectSharedState = createFeatureSelector<State, SharedState>(
  featureKey
);

export const selectBooking = createSelector(
  selectSharedState,
  state => state[fromBooking.featureKey].booking
);

export const selectBookingLoadError = createSelector(
  selectSharedState,
  state => state[fromBooking.featureKey].error
);
