import { Component, Input, OnInit } from '@angular/core';
import { HotelStay } from '../../data/core-consumer-message/hotel-stay';

@Component({
  selector: 'app-hotelinformation-tab',
  templateUrl: './hotelinformation-tab.component.html',
  styleUrls: [
    '../bookingviewer/bookingviewer.subcomponent.scss',
    './hotelinformation-tab.component.scss'
  ]
})
export class HotelinformationTabComponent implements OnInit {
  @Input() hotelStays: HotelStay[];
  roomPassengers: Map<string, string>;
  passengerNames: Map<string, string>;
  roomValueAddedOffers: Map<string, string> = new Map<string, string>();
  hotelKidsProducts: Map<number, string>;

  ngOnInit(): void {
    this.roomPassengers = this.getRoomPassengerNames(this.hotelStays);
    this.hotelKidsProducts = this.getHotelKidsProducts();
    this.passengerNames = this.getPassengerNames(this.hotelStays);
    this.roomValueAddedOffers = this.getRoomValueAddedOffers(this.hotelStays);
  }

  private getHotelKidsProducts(): Map<number, string> {
    return new Map<number, string>(
      this.hotelStays.map((h, index) =>
        this.isFalsyOrEmpty(h.KidsProducts)
          ? [index, '-']
          : [index, h.KidsProducts.map(cd => cd.ItemCodeName).join(', ')]
      )
    );
  }
  private getPassengerNames(hotelStays: HotelStay[]): Map<string, string> {
    return new Map<string, string>(
      hotelStays
        .flatMap(hotel => hotel.Rooms)
        .flatMap(room => room.Passengers)
        .map(pax => [pax.PassengerId, ` (${pax.FirstName} ${pax.LastName})`])
    );
  }

  private getRoomValueAddedOffers(
    hotelStays: HotelStay[]
  ): Map<string, string> {
    return new Map<string, string>(
      hotelStays.flatMap((hotel, hIndex) =>
        hotel.Rooms.map((room, rIndex) => {
          return [
            `${hIndex.toString()}-${rIndex.toString()}`,
            this.isFalsyOrEmpty(room.ValueAddedOffers)
              ? ''
              : room.ValueAddedOffers.map(vao => vao.Text || vao.Code).join(
                  ', '
                )
          ];
        })
      )
    );
  }

  private getRoomPassengerNames(hotelStays: HotelStay[]): Map<string, string> {
    return new Map<string, string>(
      hotelStays.flatMap((hotel, hIndex) =>
        hotel.Rooms.map((room, rIndex) => {
          return [
            `${hIndex.toString()}-${rIndex.toString()}`,
            room.Passengers.map(
              p => `${p.FirstName} ${p.LastName} (${p.PassengerSequence})`
            ).join(', ')
          ];
        })
      )
    );
  }

  isFalsyOrEmpty(arr: any[]): boolean {
    return !arr || arr.length == 0;
  }
}
