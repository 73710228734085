<div class="information-tab">
  <div class="scrollableMemoContent">
    <ng-container *ngFor="let transfer of transfers; let i = index">
      <dsa-object-header
        class="dsaObjectHeaderStyle"
        [isExpanded]="true"
        [onlyExtended]="true"
      >
        <h3>{{ i + 1 }} - {{ transfer.Name }} ({{ transfer.Code }})</h3>
        <ul>
          <li>
            <span class="title">Passengers:</span>
            <span>
              {{ passengerMap.get(i) }}
            </span>
            <span> Service Status:</span>
            <span>
              {{ transfer.ServiceStatus || "-" }}
            </span>
            <span class="title">Transfer Start Date:</span>
            <span>
              {{ (transfer.StartDate | date: "dd/MM/yyyy") || " - " }}
            </span>
            <span>Advising via:</span>
            <span>{{ transfer.AdvisingVia || "-" }}</span>
          </li>
        </ul>
      </dsa-object-header>
    </ng-container>
  </div>
</div>
