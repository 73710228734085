import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  DataServiceError,
  DefaultDataServiceConfig,
  DefaultDataServiceFactory,
  EntityCollectionReducerMethodsFactory,
  EntityDataModule,
  HttpUrlGenerator,
  PersistenceResultHandler
} from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StorageModule } from '@ngx-pwa/local-storage';
import { OAuthModule } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './modules/core/authentication/auth.interceptor';
import { CoreModule } from './modules/core/core.module';
import { AdditionalEntityCollectionReducerMethodsFactory } from './modules/core/data-customization/additional-entity-collection-reducer-methods-factory';
import { AdditionalPropertyPersistenceResultHandler } from './modules/core/data-customization/additional-property-persistence-result-handler';
import { CoreDataServiceFactory } from './modules/core/data-customization/core-data-service-factory.service';
import { PluralHttpUrlGenerator } from './modules/core/data-customization/plural-http-url-generator';
import { Effects } from './modules/core/effects/index';
import { ApiEndpointsService } from './modules/core/services/api-endpoints.service';
import { SharedModule } from './modules/shared/shared.module';
import { metaReducers, REDUCERS } from './reducers';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { createErrorHandler, TraceService } from '@sentry/angular';
import { RouteReuseStrategy, Router } from '@angular/router';
import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy';

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    CoreModule,
    SharedModule,
    StoreModule.forRoot(REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    EffectsModule.forRoot(Effects),
    EntityDataModule.forRoot({}),
    StoreRouterConnectingModule.forRoot(),
    StorageModule.forRoot({ IDBNoWrap: true }),
    OAuthModule.forRoot(),
    NgxMaskDirective,
    NgxMaskPipe
  ],
  providers: [
    {
      provide: TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        // This is intentional
      },
      deps: [TraceService],
      multi: true
    },
    {
      provide: DefaultDataServiceConfig,
      useValue: environment.defaultDataServiceConfig
    },
    {
      provide: ApiEndpointsService,
      useValue: new ApiEndpointsService(environment.apiEndpointsConfig)
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        extractor: (err, defaultExtractor) =>
          defaultExtractor(unwrapNgrxDataError(err)) || JSON.stringify(err)
      })
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HttpUrlGenerator, useClass: PluralHttpUrlGenerator },
    { provide: DefaultDataServiceFactory, useClass: CoreDataServiceFactory },
    {
      provide: PersistenceResultHandler,
      useClass: AdditionalPropertyPersistenceResultHandler
    },
    {
      provide: EntityCollectionReducerMethodsFactory,
      useClass: AdditionalEntityCollectionReducerMethodsFactory
    },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    provideNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

function unwrapNgrxDataError(err: unknown): unknown {
  return err instanceof DataServiceError ? err.error : err;
}
