import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ToastActions } from '../../core/actions/toast.action';
import { BookingActions } from '../actions/booking.actions';
import { BookingService } from '../services/booking.service';

@Injectable()
export class BookingEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly bookingService: BookingService
  ) {}

  onRequestBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingActions.getBooking),
      switchMap(req =>
        this.bookingService
          .get(req.tourOperator, req.externalReservationNumber, req.version)
          .pipe(
            map(returnedBooking =>
              BookingActions.getBookingSuccess({
                booking: returnedBooking
              })
            ),
            catchError(error => {
              return of(
                BookingActions.getBookingFailure({ error: error.message }),
                ToastActions.errorToast({ message: error.message })
              );
            })
          )
      )
    )
  );
}
