import { Component, Input } from '@angular/core';
import { Passenger } from '../../data/core-consumer-message/passenger';
@Component({
  selector: 'app-passenger-information-tab',
  templateUrl: './passenger-information-tab.component.html',
  styleUrls: [
    '../bookingviewer/bookingviewer.subcomponent.scss',
    './passenger-information-tab.component.scss'
  ]
})
export class PassengerInformationTabComponent {
  @Input() public passengers: Passenger[];
}
