import { Flight, flightStubs } from './flight';
import { HotelStay, hotelStayStubs } from './hotel-stay';
import { Passenger, passengerStubs } from './passenger';
import { StandardMemo, standardMemoStubs } from './standard-memo';
import {
  Transfer,
  transferStubs,
  transferWithNoStartDateStubs
} from './transfer';

export interface Booking {
  BookingNumber: string;
  CreatedDateTime: string;
  BookingConfirmedDateTime: string | null;
  CancelledDateTime: string | null;
  BookingStatus: 'Option' | 'Booking' | 'Cancellation';
  ProductCode: string;
  ProductName: string;
  Passengers: Passenger[];
  Flights: Flight[];
  HotelStays: HotelStay[];
  Transfers: Transfer[];
  BookingMemos: StandardMemo[];
  ReservationStatus: null | 'Confirmed' | 'Request';
}

export const bookingStub: Booking = {
  BookingNumber: '9876543',
  CreatedDateTime: '2022-01-21T05:00:00',
  BookingConfirmedDateTime: '2022-01-18T04:00:00',
  CancelledDateTime: null,
  BookingStatus: 'Booking',
  ProductCode: 'ITS',
  ProductName: 'ITS Reisen',
  Passengers: passengerStubs,
  Flights: flightStubs,
  HotelStays: hotelStayStubs,
  Transfers: transferStubs,
  BookingMemos: standardMemoStubs,
  ReservationStatus: null
};

export const bookingWithTransferStub: Booking = {
  BookingNumber: '9876543',
  CreatedDateTime: '2022-01-21T05:00:00',
  BookingConfirmedDateTime: '2022-01-18T04:00:00',
  CancelledDateTime: null,
  BookingStatus: 'Booking',
  ProductCode: 'ITS',
  ProductName: 'ITS Reisen',
  Passengers: passengerStubs,
  Flights: flightStubs,
  HotelStays: hotelStayStubs,
  Transfers: transferWithNoStartDateStubs,
  BookingMemos: standardMemoStubs,
  ReservationStatus: null
};
