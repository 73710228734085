import { createAction, props } from '@ngrx/store';

const getRequests = createAction(
  '[Received] Get Received Requests.',
  props<{
    externalReservationNumber: string;
  }>()
);

const getRequestsSuccess = createAction(
  '[Received] Get Received Requests Success.',
  props<{ requestEntities: any }>()
);

const getRequestsFailure = createAction(
  '[Received] Get Received Requests Failure.',
  props<{ error: any }>()
);

export const StorageActions = {
  getRequests,
  getRequestsSuccess,
  getRequestsFailure
};
