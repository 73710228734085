import { Component, Input, OnInit } from '@angular/core';
import { Flight } from '../../data/core-consumer-message/flight';
import { FlightLeg } from '../../data/core-consumer-message/flight-leg';

interface FlightSummary {
  firstFlightLeg: FlightLeg;
  lastFlightLeg: FlightLeg;
  passengersString: string;
  layovers: string;
  serviceStatus: null | 'CON' | 'REQ' | 'CNX';
}

@Component({
  selector: 'app-flightinformation-tab',
  templateUrl: './flightinformation-tab.component.html',
  styleUrls: [
    '../bookingviewer/bookingviewer.subcomponent.scss',
    './flightinformation-tab.component.scss'
  ]
})
export class FlightinformationTabComponent implements OnInit {
  @Input() public flights: Flight[];
  flightSummaries: FlightSummary[];

  ngOnInit(): void {
    this.flightSummaries = this.getFlightSummaries();
  }

  private getFlightSummaries(): FlightSummary[] {
    return this.flights.map(flight => {
      const firstFlightLeg = flight.FlightLegs[0];
      const lastFlightLeg = flight.FlightLegs[flight.FlightLegs.length - 1];
      return {
        firstFlightLeg: firstFlightLeg,
        lastFlightLeg: lastFlightLeg,
        passengersString: (firstFlightLeg.Direction === 'In'
          ? firstFlightLeg
          : lastFlightLeg
        ).Passengers.map(
          p => `${p.FirstName} ${p.LastName} (${p.PassengerSequence})`
        ).join(', '),
        layovers: this.getLayovers(flight.FlightLegs),
        serviceStatus: flight.FlightLegs.reduce(
          (status, fl) => (status !== 'CON' ? status : fl.ServiceStatus),
          'CON'
        )
      };
    });
  }

  private getLayovers(flightlegs: FlightLeg[]): string {
    if (flightlegs.length === 1) {
      return '';
    } else {
      let layoverFlights = flightlegs
        .slice(1, flightlegs.length - 1)
        .map(fl => [fl.DepartureAirportCode, fl.DestinationAirportCode])
        .flat();

      layoverFlights = [...new Set(layoverFlights)];

      return `(via ${layoverFlights.join(', ')})`;
    }
  }
}
