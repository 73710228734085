import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  mergeMapHubToAction,
  signalrConnected,
  signalrHubUnstarted,
  startSignalRHub
} from 'ngrx-signalr-core';
import { map } from 'rxjs';
import { JobNotificationActions } from '../actions/job-notification.actions';
import { JobStatus } from '../data/job-notification';

interface IncomingJobNotification {
  id: string;
  user: string;
  jobName: string;
  timestamp: string;
  status: JobStatus;
  errorMessage?: string;
}

@Injectable()
export class JobNotificationEffects {
  constructor(private readonly actions$: Actions) {}

  initSignalR = createEffect(() =>
    this.actions$.pipe(
      ofType(signalrHubUnstarted),
      map(hub => startSignalRHub(hub))
    )
  );

  listenToSignalRMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signalrConnected),
      mergeMapHubToAction(
        /* istanbul ignore next */ ({ hub }) => {
          return hub.on<IncomingJobNotification>('ReceiveNotification').pipe(
            map(x =>
              JobNotificationActions.receive({
                notification: {
                  id: x.id,
                  jobName: x.jobName,
                  jobStatus: x.status,
                  time: new Date(x.timestamp),
                  error: x.errorMessage,
                  isNew: true
                }
              })
            )
          );
        }
      )
    )
  );
}
