import {
  CaptureDetail,
  captureDetailStubs,
  mixedCaptureDetailStubs
} from './capture-detail';
import {
  KidsProduct,
  kidsProductStubs,
  mixedKidsProductStubs
} from './kids-product';
import { emptyRoomStub, Room, roomStubs } from './room';
import { StandardMemo, standardMemoStubs } from './standard-memo';

export interface HotelStay {
  HotelCode: string;
  HotelName: string;
  CheckInDate: string;
  CheckOutDate: string;
  ExternalClientCode: string;
  ExternalClientResNo: string;
  ExternalSupplierName: string;
  ExternalSupplierResNo: string;
  DurationInDays: number;
  Rooms: Room[];
  KidsProducts: KidsProduct[];
  Memos: StandardMemo[];
  CaptureDetails: CaptureDetail[];
  ServiceStatus: null | 'CON' | 'REQ' | 'CNX';
  NotificationMethod: null | 'Online' | 'File' | 'Mail';
  NotficiationRecipient: string;
  Source: string;
  AdvisingVia: string;
}

export const hotelStayStub: HotelStay = {
  HotelCode: 'MAH11020',
  HotelName: 'azuLine Marina Parc',
  CheckInDate: '2021-07-03T00:00:00',
  CheckOutDate: '2021-07-10T00:00:00',
  ExternalClientCode: 'PWRK',
  ExternalClientResNo: '1TL123123ABCD',
  ExternalSupplierName: 'HOB',
  ExternalSupplierResNo: '436-3319156',
  DurationInDays: 7,
  Rooms: roomStubs,
  KidsProducts: kidsProductStubs,
  Memos: standardMemoStubs,
  CaptureDetails: captureDetailStubs,
  ServiceStatus: null,
  NotificationMethod: 'Mail',
  NotficiationRecipient: 'CORE_DVR',
  Source: 'Internal/allotment',
  AdvisingVia: 'DTS (CORE)'
};

export const hotelStayStubs: HotelStay[] = [
  {
    HotelCode: 'MAH11020',
    HotelName: 'azuLine Marina Parc',
    CheckInDate: '2021-07-03T00:00:00',
    CheckOutDate: '2021-07-10T00:00:00',
    ExternalClientCode: 'ABCD',
    ExternalClientResNo: '1TL563123ABCD',
    ExternalSupplierName: 'BOH',
    ExternalSupplierResNo: '436-3234521',
    DurationInDays: 7,
    Rooms: roomStubs,
    KidsProducts: kidsProductStubs,
    Memos: standardMemoStubs,
    CaptureDetails: captureDetailStubs,
    ServiceStatus: 'CON',
    NotificationMethod: 'File',
    NotficiationRecipient: 'NOT_CORE',
    Source: 'dynamic BOH',
    AdvisingVia: 'external File'
  },
  {
    HotelCode: 'BRP11020',
    HotelName: 'BALI Resort Parc',
    CheckInDate: '2022-05-03T12:00:00',
    CheckOutDate: '2022-05-10T11:00:00',
    ExternalClientCode: 'KRWP',
    ExternalClientResNo: '1TL123123ABCD',
    ExternalSupplierName: 'OHB',
    ExternalSupplierResNo: '435-1234567',
    DurationInDays: 5,
    Rooms: roomStubs,
    KidsProducts: mixedKidsProductStubs,
    Memos: standardMemoStubs,
    CaptureDetails: mixedCaptureDetailStubs,
    ServiceStatus: 'REQ',
    NotificationMethod: 'Online',
    NotficiationRecipient: 'CORE_AE',
    Source: 'Internal/allotment',
    AdvisingVia: 'DTS (CORE)'
  }
];

export const emptyHotelStayStubs: HotelStay[] = [
  {
    HotelCode: '',
    HotelName: '',
    CheckInDate: '',
    CheckOutDate: '',
    DurationInDays: 0,
    ExternalClientCode: '',
    ExternalClientResNo: '',
    ExternalSupplierName: '',
    ExternalSupplierResNo: '',
    Rooms: [emptyRoomStub],
    KidsProducts: [],
    Memos: [],
    CaptureDetails: [],
    ServiceStatus: null,
    NotificationMethod: null,
    NotficiationRecipient: '',
    Source: '',
    AdvisingVia: ''
  }
];
