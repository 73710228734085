import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiEndpointsService } from '../../core/services/api-endpoints.service';

@Injectable({
  providedIn: 'root'
})
export class ExceptionHistorySuggesterService {
  constructor(
    private readonly http: HttpClient,
    private readonly api: ApiEndpointsService
  ) {}

  searchSuggestions(query: string, searchField: string): Observable<string[]> {
    const url = new URL(
      'api/BookingExceptionHistories/suggest',
      this.api.endpoints.exception
    ).toString();

    return this.http
      .get<string[]>(url, {
        observe: 'response',
        responseType: 'json',
        params: new HttpParams({
          fromObject: {
            query: query || '',
            field: searchField || ''
          }
        })
      })
      .pipe(map(resp => resp.body));
  }
}
