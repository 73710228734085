import { Component, OnDestroy, OnInit } from '@angular/core';
import * as fromRoot from '@app/reducers';
import * as fromNotifications from '../../reducers/job-notifications.reducer';
import { Store } from '@ngrx/store';
import { createSignalRHub, stopSignalRHub } from 'ngrx-signalr-core';
import { Observable, Subscription, map } from 'rxjs';
import { JobNotificationActions } from '../../actions/job-notification.actions';
import { ApiEndpointsService } from '@app/modules/core/services/api-endpoints.service';
import { JobNotification, JobStatus } from '../../data/job-notification';
import { InfoCardType } from '@dsa/design-system-angular';

@Component({
  selector: 'app-notifications-display',
  templateUrl: './job-notifications-display.component.html',
  styleUrls: ['./job-notifications-display.component.scss']
})
export class JobNotificationsDisplayComponent implements OnInit, OnDestroy {
  isOpen = false;

  notifications$: Observable<JobNotification[]>;

  newCount$: Observable<number>;

  private hub: any;

  private token: string;

  private subscriptions = new Subscription();

  constructor(
    private readonly rootStore: Store<fromRoot.State>,
    private readonly notificationStore: Store<fromNotifications.State>,
    private readonly apiEndpointsService: ApiEndpointsService
  ) {
    this.notifications$ = this.notificationStore.select(
      fromNotifications.selectNotifications
    );
    this.newCount$ = this.notifications$.pipe(
      map(xs => xs.filter(x => x.isNew).length)
    );

    this.hub = {
      hubName: 'Job Notifications',
      url: `${this.apiEndpointsService.endpoints.dynamicConnect}/notify`,
      automaticReconnect: true,
      options: {
        accessTokenFactory: /* istanbul ignore next */ () => this.token
      }
    };

    this.subscriptions.add(
      this.rootStore
        .select(fromRoot.selectToken)
        .subscribe(({ token }) => (this.token = token))
    );
  }

  ngOnInit(): void {
    this.rootStore.dispatch(createSignalRHub(this.hub));
  }

  ngOnDestroy(): void {
    this.rootStore.dispatch(stopSignalRHub(this.hub));
    this.subscriptions.unsubscribe();
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
    if (!this.isOpen) {
      this.notificationStore.dispatch(JobNotificationActions.read());
    }
  }

  getId(notification: JobNotification): string {
    return notification.id;
  }

  remove(notification: JobNotification): void {
    this.notificationStore.dispatch(
      JobNotificationActions.remove({ notification })
    );
  }

  getType(status: JobStatus): InfoCardType {
    switch (status) {
      case 'Scheduled':
      case 'Enqueued':
      case 'Started':
        return 'info';
      case 'Finished':
        return 'success';
      case 'Failed':
        return 'error';
      case 'Deleted':
        return 'warning';
      default:
        throw new Error(`Unknown status '${status}'.`);
    }
  }
}
