import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ToastActions } from '../../../../core/actions/toast.action';
import { StorageActions } from '../actions/storage.actions';
import { ReceivedService } from '../services/received.service';

@Injectable()
export class ReceivedEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly receivedService: ReceivedService
  ) {}

  onRequestRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageActions.getRequests),
      switchMap(req =>
        this.receivedService.get(req.externalReservationNumber).pipe(
          map(requestEntities =>
            StorageActions.getRequestsSuccess({
              requestEntities: requestEntities
            })
          ),
          catchError(error => {
            return of(
              StorageActions.getRequestsFailure({ error: error.message }),
              ToastActions.errorToast({ message: error.message })
            );
          })
        )
      )
    )
  );
}
