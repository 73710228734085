import {
  arrivalflightLegStub,
  arrivalflightLegStubs,
  departureflightLegStubs,
  FlightLeg
} from './flight-leg';

export interface Flight {
  FlightLegs: FlightLeg[];
}

export const flightStubs: Flight[] = [
  {
    FlightLegs: [arrivalflightLegStub]
  },
  {
    FlightLegs: arrivalflightLegStubs
  },
  {
    FlightLegs: departureflightLegStubs
  }
];
