import { Component, Inject } from '@angular/core';
import { environment } from '@env/environment';
import { DataToSend } from '@dsa/design-system-angular/feedback-form';
import { WindowToken } from '../../../shared/providers/window';

@Component({
  selector: 'app-service-desk-feedback',
  templateUrl: './service-desk-feedback.component.html',
  styleUrls: ['./service-desk-feedback.component.scss']
})
export class ServiceDeskFeedbackComponent {
  constructor(@Inject(WindowToken) private window: Window) {}

  onSend(data: DataToSend) {
    const emailSub = 'Feedback for CORE Team';
    const emailBody = `Face Selected: ${data.selectedFace}
Message: ${data.message || 'The user did not include any message.'}

Environment: ${environment.name}`;

    this.window.location.href = `mailto:${
      environment.serviceDeskFeedbackEmail
    }?subject=${emailSub}&body=${encodeURI(emailBody)}`;
  }
}
