import { createAction, props } from '@ngrx/store';
import { SearchFields } from '../reducers/bookingexceptionsuggester.reducer';

const suggestRequest = createAction(
  '[Suggester] BookingExceptions Request.',
  props<{
    searchString: string;
    searchField: SearchFields;
  }>()
);

const suggestSuccess = createAction(
  '[Suggester] BookingExceptions Success.',
  props<{
    searchField: SearchFields;
    suggestions: string[];
  }>()
);

export const SuggesterActions = {
  suggestRequest,
  suggestSuccess
};
