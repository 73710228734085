import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from './modules/core/guards/role.guard';
import { AccessDeniedComponent } from './modules/core/container/access-denied/access-denied.component';
import { CoreModule } from './modules/core/core.module';
import { StartPageComponent } from './modules/core/container/startpage/startpage.component';

export const routes: Routes = [
  {
    path: '',
    component: StartPageComponent
  },
  {
    path: 'booking',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./modules/booking/booking.module').then(mod => mod.BookingModule)
  },
  {
    path: 'exception',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./modules/exception/exception.module').then(
        mod => mod.ExceptionModule
      )
  },
  {
    path: 'exceptions',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./modules/exception/exception.module').then(
        mod => mod.ExceptionModule
      )
  },
  {
    path: 'dynamic-connect/exceptions',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./modules/exception/exception.module').then(
        mod => mod.ExceptionModule
      )
  },
  {
    path: 'dynamic-connect',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./modules/dynamic-connect/dynamic-connect.module').then(
        mod => mod.DynamicConnectModule
      )
  },
  {
    path: 'accessDenied',
    component: AccessDeniedComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'disabled',
      canceledNavigationResolution: 'computed',
      onSameUrlNavigation: 'reload'
    }),
    CoreModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
