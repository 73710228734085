import {
  EntityAction,
  DefaultPersistenceResultHandler,
  Pluralizer,
  Logger,
  EntityActionFactory,
  EntityOp
} from '@ngrx/data';
import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';

@Injectable()
export class AdditionalPropertyPersistenceResultHandler extends DefaultPersistenceResultHandler {
  constructor(
    logger: Logger,
    entityActionFactory: EntityActionFactory,
    private readonly pluralizer: Pluralizer
  ) {
    super(logger, entityActionFactory);
  }

  private readonly pluralizedOps = [EntityOp.QUERY_ALL, EntityOp.QUERY_MANY];

  handleSuccess(originalAction: EntityAction): (data: any) => Action {
    const actionHandler = super.handleSuccess(originalAction);

    return (data: any) => {
      const entityPropName = this.toCamelCase(
        originalAction.payload.entityName
      );

      const pluralEntityPropName = this.pluralizer.pluralize(entityPropName);

      let successData: any;
      if (this.pluralizedOps.includes(originalAction.payload.entityOp)) {
        successData = data[pluralEntityPropName];
      } else if (originalAction.payload.entityOp === EntityOp.SAVE_UPDATE_ONE) {
        successData = data;
      } else {
        successData = data[entityPropName];
      }

      const action = actionHandler.call(this, successData);
      if (action && data && data.metadata) {
        action.payload.metadata = data.metadata;
      }
      return action;
    };
  }

  private toCamelCase(s: string): string {
    return s && s[0].toLowerCase() + s.slice(1);
  }
}
