import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import * as dayjs from 'dayjs';
import { enLocale } from '../../data/datepickerlocale';

@Component({
  selector: 'app-datetimepicker',
  templateUrl: './datetimepicker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true
    }
  ]
})
export class DateTimePickerComponent implements OnInit, ControlValueAccessor {
  @Input() datePlaceholder: string;
  @Input() timePlaceholder: string;

  public dateForm = new FormControl<Date | null>(null);
  public timeForm = new FormControl<string | null>(null);
  public locale = enLocale;
  private dateTime = null;

  public onTouched: () => void;
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onChange: (dateTime: string) => void;
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  writeValue(value: Date): void {
    if (value) {
      this.dateTime = dayjs(value);
      this.dateForm.patchValue(this.dateTime.toDate());
      this.timeForm.patchValue(this.dateTime.format('HH:mm'));
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.dateForm.disable();
      this.timeForm.disable();
    } else {
      this.dateForm.enable();
      this.timeForm.enable();
    }
  }

  ngOnInit() {
    this.dateForm.valueChanges.subscribe((date: Date) => {
      this.setDateTime(date);
    });
    this.timeForm.valueChanges.subscribe(timeString => {
      this.setTime(timeString);
    });
  }

  private setDateTime(date: Date) {
    if (date) {
      this.dateTime = dayjs(date);
      if (this.timeForm.value) {
        this.setTime(this.timeForm.value);
      }
    } else {
      this.dateTime = null;
    }

    if (this.onChange) {
      this.onChange(this.dateTime?.format('YYYY-MM-DDTHH:mm:ss'));
    }
  }

  private setTime(time: string) {
    if (time && this.dateTime) {
      const splitted = time.split(':');
      const hour = splitted[0];
      const minute = splitted[1];

      this.dateTime = this.dateTime
        .set('hour', parseInt(hour))
        .set('minute', parseInt(minute));
    }

    if (this.onChange) {
      this.onChange(this.dateTime?.format('YYYY-MM-DDTHH:mm:ss'));
    }
  }
}
