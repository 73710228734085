import { ActionReducerMap, createSelector, MetaReducer } from '@ngrx/store';

import * as fromRouter from '@ngrx/router-store';
import * as fromCore from '../modules/core/reducers/core.reducer';
import { InjectionToken } from '@angular/core';
import { signalrReducer } from 'ngrx-signalr-core';

export interface State {
  router: fromRouter.RouterReducerState<any>;
  [fromCore.featureKey]: fromCore.State;
}

export const REDUCERS = new InjectionToken<ActionReducerMap<State>>(
  'reducers token',
  {
    factory: () => ({
      [fromCore.featureKey]: fromCore.reducer,
      router: fromRouter.routerReducer,
      signalr: signalrReducer
    })
  }
);

export const metaReducers: MetaReducer<State>[] = [];

export const selectCoreState = (state: State) => state[fromCore.featureKey];

export const selectLoadingFeatureModuleState = createSelector(
  selectCoreState,
  fromCore.selectLoadingFeatureModuleState
);

export const selectAuthState = createSelector(
  selectCoreState,
  fromCore.selectAuthState
);

export const selectToken = createSelector(
  selectCoreState,
  fromCore.selectToken
);

export const selectTenants = createSelector(
  selectCoreState,
  fromCore.selectTenants
);

export const selectActiveModule = createSelector(
  selectCoreState,
  fromCore.selectActiveModule
);
