<div class="information-tab">
  <div class="scrollableMemoContent">
    <ng-container *ngFor="let hotel of hotelStays; let hotelIdx = index">
      <dsa-object-header
        class="objectHeader"
        [isExpanded]="true"
        [onlyExtended]="true"
      >
        <h3>{{ hotel.HotelName || "-" }} ({{ hotel.HotelCode || "-" }})</h3>
        <ul>
          <li>
            <span>CheckIn:</span>
            <span>{{ (hotel.CheckInDate | date: "dd/MM/yyyy") || "-" }}</span>
            <span>Checkout:</span>
            <span>{{ (hotel.CheckOutDate | date: "dd/MM/yyyy") || "-" }}</span>
          </li>
          <li>
            <span>Memos:</span>
            <span *ngIf="!isFalsyOrEmpty(hotel.Memos)"
              ><ng-container *ngFor="let memo of hotel.Memos">
                {{ memo.CodeName }} - {{ memo.Description }}
                <br />
              </ng-container>
            </span>
            <span *ngIf="isFalsyOrEmpty(hotel.Memos)">-</span>
            <span>Duration:</span>
            <span>{{ hotel.DurationInDays || "-" }} night(s)</span>
          </li>
          <li>
            <span>Capture Details:</span>
            <span *ngIf="!isFalsyOrEmpty(hotel.CaptureDetails)"
              ><ng-container *ngFor="let captureDetail of hotel.CaptureDetails">
                {{ captureDetail.Name }}: {{ captureDetail.Value }}
                {{ passengerNames.get(captureDetail.PassengerId) }}
                <br />
              </ng-container>
            </span>
            <span *ngIf="isFalsyOrEmpty(hotel.CaptureDetails)">-</span>
            <span>Kids Products:</span>
            <span>{{ hotelKidsProducts.get(hotelIdx) }}</span>
          </li>
          <li>
            <span> Service Status: </span>
            <span>
              {{ hotel.ServiceStatus || "-" }}
            </span>
            <span> Source:</span>
            <span>
              {{ hotel.Source || "-"}}
            </span>
          </li>
          <li>
            <span>Advising via:</span>
            <span>
              {{hotel.AdvisingVia || "-" }}
            </span>
          </li>
        </ul>
        <ng-container *ngFor="let room of hotel.Rooms; let roomIdx = index">
          <dsa-object-header
            class="inlineObjectHeader"
            [isExpanded]="true"
            [onlyExtended]="true"
          >
            <h3>
              {{ room.RoomTypeName || "-" }} ({{ room.RoomTypeCode || "-" }})
            </h3>
            <ul>
              <li>
                <span>Room Category:</span>
                <span>{{ room.RoomCategoryName || "-" }}</span>
                <span>Room Description:</span>
                <span>{{ room.RoomDescription || "-" }}</span>
              </li>
              <li>
                <span>Board:</span>
                <span
                  >{{ room.BoardBasisName || "-" }} ({{
                    room.BoardBasisCode || "-"
                  }})</span
                >
                <span>Cost:</span>
                <span
                  >{{ room.TotalCost || "-" }}
                  {{ room.TotalCostCurrencyCode || "-" }}</span
                >
              </li>
              <li>
                <span>Applied Offers:</span>
                <span *ngIf="!isFalsyOrEmpty(room.OfferCodes)">
                  <ng-container *ngFor="let offer of room.OfferCodes">
                    {{ offer.Offer || "-" }},
                  </ng-container>
                </span>
                <span *ngIf="isFalsyOrEmpty(room.OfferCodes)">-</span>
                <span>Total Pax (Adult/Child/Infant):</span>
                <span
                  >{{ room.NoOfPaxTotal || "-" }} ({{
                    room.NoOfPaxAdult || "-"
                  }}
                  / {{ room.NoOfPaxChild || "-" }} /
                  {{ room.NoOfPaxInfant || "-" }})</span
                >
              </li>
              <li>
                <span>Capture Details:</span>
                <span *ngIf="!isFalsyOrEmpty(room.CaptureDetails)"
                  ><ng-container
                    *ngFor="let captureDetail of room.CaptureDetails"
                    >{{ captureDetail.Name }}: {{ captureDetail.Value }}
                    {{ passengerNames.get(captureDetail.PassengerId) }} <br
                  /></ng-container>
                </span>
                <span *ngIf="isFalsyOrEmpty(room.CaptureDetails)">-</span>
                <span>Value Added Offers:</span>
                <span>
                  {{ roomValueAddedOffers.get(hotelIdx + "-" + roomIdx) }}
                </span>
              </li>
              <hr />
              <li>
                <span>Passengers:</span>
                <span>
                  {{ roomPassengers.get(hotelIdx + "-" + roomIdx) }}
                </span>
              </li>
            </ul>
          </dsa-object-header>
        </ng-container>
      </dsa-object-header>
    </ng-container>
  </div>
</div>
