import { Component, Input, OnInit } from '@angular/core';
import { Transfer } from '../../data/core-consumer-message/transfer';

@Component({
  selector: 'app-transferinformation-tab',
  templateUrl: './transferinformation-tab.component.html',
  styleUrls: [
    '../bookingviewer/bookingviewer.subcomponent.scss',
    './transferinformation-tab.component.scss'
  ]
})
export class TransferinformationTabComponent implements OnInit {
  @Input() public transfers: Transfer[];
  public passengerMap: Map<number, string>;

  ngOnInit(): void {
    this.passengerMap = new Map<number, string>(
      this.transfers.map((transfer, index) => {
        return [
          index,
          transfer.Passengers.map(
            p => `${p.FirstName} ${p.LastName} (${p.PassengerSequence})`
          ).join(' / ')
        ];
      })
    );
  }
}
