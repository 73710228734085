import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpointsService } from '@app/modules/core/services/api-endpoints.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoomType } from '../data/masterdata/room-type';

@Injectable({
  providedIn: 'root'
})
export class MasterdataService {
  constructor(
    private readonly http: HttpClient,
    private readonly api: ApiEndpointsService
  ) {}

  getRoomTypes(tenant: string, roomInput: string): Observable<RoomType[]> {
    if (!roomInput) {
      return of([]);
    }

    const url = this.api.endpoints.masterdata.concat(
      `/roomtypes/search/${tenant}?namePart=${encodeURIComponent(roomInput)}`
    );

    return this.http
      .get<RoomType[]>(url, {
        observe: 'response',
        responseType: 'json'
      })
      .pipe(map(resp => resp.body));
  }
}
