import { createAction, props } from '@ngrx/store';
import { FilterParams } from '../data/filterparam';
import { Hotel } from '../data/hotel';
import { HotelIndex } from '../data/hotel-index';
import { SearchOutput } from '../data/searchOutput';

const searchHotels = createAction(
  '[Hotel] Search Hotels',
  props<{
    filterParams?: FilterParams;
    force: boolean;
  }>()
);

const searchHotelsSuccess = createAction(
  '[Hotel] Search Hotels successfully',
  props<{
    searchOutput: SearchOutput<HotelIndex>;
  }>()
);

const getHotel = createAction(
  '[Hotel] Get Hotel',
  props<{
    hotelCode: string;
    hotelChainCode: string;
    supplier: string;
  }>()
);

const getHotelSuccess = createAction(
  '[Hotel] Get Hotel successfully',
  props<{
    hotel: Hotel;
  }>()
);

const deleteHotel = createAction(
  '[Hotel] Delete Hotel',
  props<{
    hotel: HotelIndex;
  }>()
);

const deleteHotelSuccess = createAction('[Hotel] Delete Hotel successfully');

const deleteContent = createAction(
  '[Hotel] Delete Content',
  props<{
    hotelCode: string;
    hotelChainCode: string;
    supplier: string;
  }>()
);

const deleteContentSuccess = createAction(
  '[Hotel] Delete Content successfully'
);

const validateHotel = createAction(
  '[Hotel] Validate Hotel',
  props<{
    hotelCode: string;
    hotelChainCode: string;
    supplier: string;
  }>()
);

const validateHotelFailure = createAction(
  '[Hotel] Validate Hotel Failure',
  props<{ errorMessage: string }>()
);

const setSaveButtonDisabled = createAction(
  '[Hotel] Set Hotel SaveButton Disabled',
  props<{ isDisabled: boolean }>()
);

const setSaveButtonDisabledSuccess = createAction(
  '[Hotel] Set SaveButton Disabled Success'
);

const upsertHotel = createAction(
  '[Hotel] Upsert hotel',
  props<{ hotel: Hotel }>()
);

const upsertHotelNavigateBackOnSuccess = createAction(
  '[Hotel] Upsert hotel and navigate to hotels on success',
  props<{ hotel: Hotel }>()
);

const upsertHotelSuccess = createAction('[Hotel] Upsert hotel success');

export const HotelActions = {
  getHotel,
  getHotelSuccess,
  searchHotels,
  searchHotelsSuccess,
  deleteHotel,
  deleteHotelSuccess,
  deleteContent,
  deleteContentSuccess,
  validateHotel,
  validateHotelFailure,
  setSaveButtonDisabled,
  setSaveButtonDisabledSuccess,
  upsertHotel,
  upsertHotelSuccess,
  upsertHotelNavigateBackOnSuccess
};
