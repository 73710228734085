import { createAction, props } from '@ngrx/store';
import { Hotel } from '../data/hotel';

const deleteHotelAri = createAction(
  '[Ari] Delete Ari of whole Hotel',
  props<{
    hotel: Hotel;
  }>()
);

const deleteAriSuccess = createAction('[Ari] Delete Ari successfully');

export const AriActions = {
  deleteHotelAri,
  deleteAriSuccess
};
