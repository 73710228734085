import { createAction, props } from '@ngrx/store';
import { RoomType } from '../data/masterdata/room-type';

const getRoomTypes = createAction(
  '[MasterData] Get Roomtypes from Masterdata',
  props<{
    tenant: string;
    roomInput: string;
  }>()
);

const getRoomTypesSuccess = createAction(
  '[MasterData] Get Roomtypes returned successfully',
  props<{
    roomTypes: RoomType[];
  }>()
);

export const MasterDataActions = {
  getRoomTypes,
  getRoomTypesSuccess
};
